<template>
    <div>
        <v-container>
            <h4>Permissies</h4>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>
                            <h4>Algemeen</h4>
                        </v-card-title>
                        <v-card-text>
                            <v-treeview :items="generalItems" selection-type="independent"  v-model="permissions.general" activatable item-key="id" open-on-click  item-children="children" selectable>
                            </v-treeview>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card>
                        <v-card-title>
                            <h4>Commissies</h4>
                        </v-card-title>
                        <v-card-text>
                            <v-treeview :items="commissionItems" v-model="permissions.commissions" selection-type="independent"  activatable item-key="id" open-on-click  item-children="children"  selectable>
                            </v-treeview>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

export default {
    data() {
        return {
            permissions: {
                general: [],
                commissions: []
            },
        }
    },
    props: {
       
        newUser: {
            type: Boolean,
            default: false
        },
        userPermissions: {
            type: Object,
            default: () => {
                return {
                    general: [],
                    commissions: []
                }
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        commissions() {
            return this.$store.state.commissionStore.commissions;
        },
        commissionItems() {
            let items = [];
            this.commissions.forEach(commission => {
                items.push({
                    id: commission.id,
                    name: commission.name,
                    locked: false,
                    index: commission.index,
                    children: [
                        {
                            id: commission.id + '-vz',
                            name: 'Voorzitter',
                        }
                    ]
                });
            });
            items.sort((a, b) => a.index - b.index);
            return items;
        },
        generalItems() {
            return this.$store.state.permissions.generalPermissions;
        }
    },
    watch: {
        permissions: {
            handler() {
                this.$emit('update-permissions', this.permissions);
            },
            deep: true
        },
        userPermissions: {
            handler() {
                this.permissions = this.userPermissions;
            },
            deep: true
        }
    },
    methods: {
        
    },

    mounted() {
        if(this.userPermissions){
            this.permissions = this.userPermissions;
        }
    }



}

</script>
<style scoped>
.left-align-checkbox .v-label {
  text-align: left;
}
</style>