<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-1 justify-space-between">
      <h1>
        <span class="ahcRed">Nieuw product toevoegen</span>
      </h1>
    </v-layout>
    <v-card>
      <v-system-bar class="pa-4" color="#ebb63b"> </v-system-bar>
      <v-form class="ma-3">
        <v-text-field
          v-model="product.title"
          label="Naam van het product (Verplicht)"
          outlined
        >
        </v-text-field>
        <v-textarea
          v-model="product.description"
          outlined
          label="Product beschrijving"
        ></v-textarea>
        <v-text-field
          v-model="product.price"
          outlined
          label="Verkoops prijs (Verplicht)"
          type="number"
          prefix="€"
        ></v-text-field>
        <v-text-field
          label="Initiele voorraad (Verplicht)"
          v-model="product.initialStock"
          type="number"
        ></v-text-field>
        <v-textarea
          v-model="product.suppliers"
          label="Leveranciers (bestelhistorie, contactgegevens, prijzen, etc)"
        ></v-textarea>
      </v-form>
      <v-row class="ma-3">
        <v-btn class="my-3" router :to="'/voorraad/producten'">Annuleren</v-btn>
        <v-btn
          class="ma-3"
          @click="addProduct"
          v-if="
            product.title &&
            product.price &&
            product.initialStock
          "
          >Opslaan</v-btn
        >
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import router from "../../router/index";
export default {
  name: "AddNewProduct",
  data() {
    return {
      product: {
        changelog: [],
      },
      newImage: null,
    };
  },
  computed: {
    userName() {
      return this.$store.state.currentUser.name;
    },
    stock() {
      let stock = [];
      if (this.$store.state.webshop !== null) {
        stock = this.$store.state.webshop.stock;
      }
      return stock;
    },
  },
  methods: {
    addProduct() {
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let createdMsg = `Product is aangemaakt door ${this.userName} op ${creationDate}`;
      this.product.id = Date.now();
      this.product.category = "nvt";
      this.product.price = parseFloat(
        parseFloat(this.product.price).toFixed(2)
      );
      this.product.initialStock = parseInt(this.product.initialStock);
      this.product.changelog.push(createdMsg);
      this.stock.forEach((x) => {
        if (x.title === "Algemeen") {
          x.products.push({
            title: this.product.title,
            amount: parseInt(this.product.initialStock),
            id: this.product.id,
          });
        } else {
          x.products.push({
            title: this.product.title,
            amount: 0,
            id: this.product.id,
          });
        }
      });
      delete this.product.initialStock;
      this.$store.state.webshop.products.push(this.product);
      this.$store
        .dispatch("saveWebshop")
        .then(() => {
          router.push({ name: "ProductOverview" });
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
};
</script>

<style>
</style>