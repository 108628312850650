<template>
    <v-container>

<v-alert outlined color="#ebb63b">
    <v-flex xs12 class="ma-2">
      <v-row>
        <v-col class="d-flex justify-center">
          <v-card flat
            ><v-chip v-if="advertisers" color="#ebb63b" text-color="#cc212f"
              >Benaderd: {{ advertisers.length }}</v-chip
            ></v-card
          >
        </v-col>
        <v-col class="d-flex justify-center">
          <v-card flat
            ><v-chip color="#ebb63b" text-color="#cc212f"
              >Open: {{
                openAdvertisementsCount
              }}</v-chip
            ></v-card
          >
        </v-col>
        <v-col class="d-flex justify-center">
          <v-card flat
            ><v-chip color="#ebb63b" text-color="#cc212f"
              >Verkocht: {{
                soldAdvertisementsCount
              }}</v-chip
            ></v-card
          >
        </v-col>
        <v-col class="d-flex justify-center">
          <v-card flat
            ><v-chip color="#ebb63b" text-color="#cc212f"
              >Niet verkocht: {{
                notSoldAdvertisementsCount
              }}</v-chip
            ></v-card
          >
        </v-col>
       
        <v-col class="d-flex justify-center">
          <v-card flat
            ><v-chip color="#ebb63b" text-color="#cc212f"
              >Opbrengst: €{{
                soldAdvertisementsAmount
              }},-</v-chip
            ></v-card
          >
        </v-col>
      </v-row>
    </v-flex>
  </v-alert>

</v-container>

</template>

<script>
export default {
    name: "TotalenAdvertenties",
    props:{
        advertisers:{
            type:Array,
            required:true,
            default: () => [],
        },
        advertisements:{
            type:Array,
            required:true,
            default: () => [],
        },
    },
  computed:{
    openAdvertisementsCount(){
      let myAdvertisers = this.advertisers;
      let myOpenAdvertisementsCount = 0;
      myAdvertisers.forEach(advertiser => {
        let advertisement = this.advertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
        if(!advertisement || advertisement.advState == "open"){
          myOpenAdvertisementsCount++
        }
      });
      return myOpenAdvertisementsCount;
    },
    notSoldAdvertisementsCount(){
      let myAdvertisers = this.advertisers;
      let NotSoldAdvertisementsCount = 0;
      myAdvertisers.forEach(advertiser => {
        let advertisement = this.advertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
        if(advertisement && advertisement.advState == "niet verkocht"){
          NotSoldAdvertisementsCount++
        }
      });
      return NotSoldAdvertisementsCount;
    },
    soldAdvertisementsCount(){
      let myAdvertisers = this.advertisers;
      let SoldAdvertisementsCount = 0;
      myAdvertisers.forEach(advertiser => {
        let advertisement = this.advertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
        if(advertisement && advertisement.advState == "verkocht"){
          SoldAdvertisementsCount++
        }
      });
      return SoldAdvertisementsCount;
    },
    soldAdvertisementsAmount(){
      let myAdvertisers = this.advertisers;
      let SoldAdvertisementsAmount = 0;
      myAdvertisers.forEach(advertiser => {
        let advertisement = this.advertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
        if(advertisement && advertisement.advState == "verkocht" && advertisement.advPaymentMethod !== "gratis"){
            
          SoldAdvertisementsAmount += advertisement.advPrice ?? 0;
        }
      });
      return SoldAdvertisementsAmount;
    },
  },
  }


</script>