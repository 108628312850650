<template>
    <v-container>
        <v-switch v-model="allAdds" label="Alle advertenties / nog te plaatsen" class="ma-5"></v-switch>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" class="ma-5" single-line
            hide-details></v-text-field>
        <v-data-table
            :headers="headers"
            :items="allAdds ? allAddsToPlace : addsToPlace " class="elevation-1" disable-pagination hide-default-footer 
             :search="search" sort-by="advFormat">
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem = item, openDialog= true">
                            mdi-sort-bool-ascending
                        </v-icon>
                    </template>
                    <span>plaats</span>
                </v-tooltip>
                
            </template>
            <template v-slot:[`item.advFormat`]="{ item }">
            <span v-if="!item.placed">{{ item.advFormat }}</span>
            <strike v-else>{{ item.advFormat }}</strike>
          </template>
          <template v-slot:[`item.advertiserUserId`]="{ item }">
            <span v-if="!item.placed">{{ item.advertiserUserId }}</span>
            <strike v-else>{{ item.advertiserUserId }}</strike>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span v-if="!item.placed">{{ item.name }}</span>
            <strike v-else>{{ item.name }}</strike>
          </template>
          <template v-slot:[`item.readyForPlacement`]="{ item }">
            <v-icon :color="getStatusColor(item)" dark>
                  mdi-circle
                </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="openDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Geplaatst op pagina:</span>
      </v-card-title>
      <v-card-text>
        <v-text-field label="Geplaatst op pagina:" v-model="editItem.placedOnPage"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="openDialog = false, editItem = {}">Annuleren</v-btn>
        <v-btn color="blue darken-1" text @click="place()">Bevestigen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    </v-container>
</template>
<script>

export default {
    name: "FormatOverview",
    props: {
        format: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            search: "",
            contactQuestion: "",
            headers: [
                { text: 'Formaat', value: 'advFormat', align: 'start' },
                { text: 'nr', value: 'advertiserUserId' },
                { text: 'Bedrijfsnaam', value: 'name' },
                {text: 'Geplaatst', value: 'placedOnPage'},
                { text: 'Voorbereid', value: 'readyForPlacement' },
                { text: 'Plaats', value: 'actions' },
            ],
            openDialog: false,
            editItem: {},
            allAdds: true,
        }
    },
    computed: {
        advertisers() {
            return this.$store.state.paperStore.advertisers;
        },
        activeAdvertisements() {
            return this.$store.state.paperStore.activeAdvertisements;
        },
        activeEdition() {
            let activeEditionId = this.$store.state.paperStore.activeEditionId;
            let editions = this.$store.state.paperStore.paperEditions;
            return editions.find(edition => edition.id == activeEditionId);
        },
        userName() {
            return this.$store.state.currentUser.name;
        },
        allAds() {
            let ads = [];
            this.advertisers.forEach(advertiser => {
                let ad = this.activeAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
                if (ad) {
                    if(ad.advState === "verkocht"){
                        ads.push(ad);
                    }
                    
                }
                
            });
            return ads
        },

        allAddsToPlace() {
            let openAds = [];
            this.allAds.forEach(ad => {
                switch (this.format) {
                    case 0:
                        if (ad?.advFormat?.substring(0,3) === "8,5") {
                            openAds.push(ad);
                        }
                        break;
                    case 1:
                    if (ad?.advFormat?.substring(0,2) === "17") {
                            openAds.push(ad);
                        }
                        break;
                        case 2:
                    if (ad?.advFormat?.substring(0,4) === "25,9") {
                            openAds.push(ad);
                        }
                        break;
                    default:
                        break;
                }
            });
            return openAds;
        },
        addsToPlace() {
            let openAds = [];
            this.allAds.forEach(ad => {
                if(!ad.placed){
                switch (this.format) {
                    case 0:
                        if (ad?.advFormat?.substring(0,3) === "8,5") {
                            openAds.push(ad);
                        }
                        break;
                    case 1:
                    if (ad?.advFormat?.substring(0,2) === "17") {
                            openAds.push(ad);
                        }
                        break;
                        case 2:
                    if (ad?.advFormat?.substring(0,4) === "25,9") {
                            openAds.push(ad);
                        }
                        break;
                    default:
                        break;
                }
            }
            });
            return openAds;
        },
    },
    methods: {
       

        place() {
            if(this.editItem.placedOnPage?.length > 0) {
                this.editItem.placed = true;
            } else {
                this.editItem.placed = false;
            }
            this.saveAdvertisement(this.editItem);
            this.openDialog = false;
            this.editItem = {};
        },
        saveAdvertisement(item) {
            this.$store.commit("paperStore/saveAdvertisement", {
                advertisement: item,
            });
        },
        getStatusColor(item) {
      let color = "white"
        if (item.readyForPlacement) {
            color = "green"	
        } else  {
            color = "red"
        }
      return color;
    },
    }
}
</script>