<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Home</span>
      </h1>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutEditModules">
      <v-flex xs12 md5 name="flexIntroAgendaWistU">
        <v-flex class="ma-2" xs12 name="flexIntro">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Introductie tekst Homepage</b>
              </span>
            </v-system-bar>
            <v-textarea
              class="pa-2"
              v-model="homeIntro"
              disabled
              v-if="lockIntro"
            ></v-textarea>
            <v-textarea
              class="pa-2"
              v-model="newIntro"
              v-if="!lockIntro"
            ></v-textarea>
            <v-card-actions>
              <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
              <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
              <v-btn v-if="!lockIntro" @click="lockIntro = true"
                >Annuleren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex class="ma-2" xs12 name="flexAgenda">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Agenda</b>
              </span>
            </v-system-bar>
            <v-card-text>
              <v-form
                align="center"
                class="ma-5 d-flex flex-row"
                v-if="!lockAgenda"
              >
                <v-flex>
                  <v-row>
                    <v-date-picker
                      v-model="newCalendarDate.date"
                      no-title
                      scrollable
                    >
                    </v-date-picker>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="newCalendarDate.name"
                      label="Naam"
                      class="grey--text"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="newCalendarDate.comment"
                      label="Opmerking"
                      class="grey--text"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <div>
                      <v-icon color="#ebb63b" @click="addCalendarItem"
                        >mdi-plus-circle</v-icon
                      >
                    </div>
                  </v-row>
                </v-flex>
              </v-form>
              <template class="ma-4" v-if="agenda && agenda.length > 0">
                <v-data-table
                  :headers="calendarHeaders"
                  :items="agenda"
                  class="elevation-0"
                  sortBy="date"
                  hide-default-footer
                  loading-text="Het jaarprogramma wordt ingeladen"
                  disable-pagination
                >
                  <template
                    v-slot:[`item.actions`]="{ item }"
                    v-if="!lockAgenda == true"
                  >
                    <v-icon
                      class="ml-5"
                      color="#cc212f"
                      @click="deleteCalendarItem(item)"
                    >
                      mdi-minus-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="(lockAgenda = false), (changeCalendar = true)"
                v-if="lockAgenda"
                >Aanpassen</v-btn
              >
              <v-btn
                v-if="!lockAgenda"
                @click="
                  updateAgenda(), (lockAgenda = true), (changeCalendar = false)
                "
                >Opslaan</v-btn
              >
              <v-btn
                v-if="!lockAgenda"
                @click="(lockAgenda = true), (changeCalendar = false)"
                >Annuleren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex xs12 md5 name="flexThemeProg">
        <v-flex class="ma-2" xs12 name="flexTheme">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Thema</b>
              </span>
            </v-system-bar>
            <v-text-field
              class="pa-2"
              v-model="themeTitle"
              disabled
              v-if="lockTheme"
              placeholder="Thema Carnaval 2022"
            ></v-text-field>
            <v-text-field
              class="pa-2"
              v-model="newThemeTitle"
              v-if="!lockTheme"
              placeholder="Thema Carnaval 2022"
            ></v-text-field>
            <v-text-field
              class="pa-2"
              v-model="themeText"
              disabled
              v-if="lockTheme"
              placeholder="Wai 44 da!"
            ></v-text-field>
            <v-text-field
              class="pa-2"
              v-model="newThemeText"
              v-if="!lockTheme"
              placeholder="Wai 44 da!"
            ></v-text-field>
            <div class="d-flex justify-center" xs12 v-if="lockTheme">
              <img
                contain
                width="80%"
                class="img-circle"
                v-if="themeImage"
                :src="themeImage.url"
              />
              <span v-if="!themeImage"><i>Geen themabord geupload</i></span>
            </div>
            <div class="d-flex justify-center" xs12 v-if="!lockTheme">
              <img
                contain
                width="80%"
                class="img-circle"
                v-if="newThemeImage"
                :src="newThemeImage.url"
              />
            </div>
            <v-btn v-if="!lockTheme && newThemeImage" @click="deleteThemeImage"
              ><v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-file-input
              v-if="!lockTheme"
              accept="image/png, image/jpeg, image/bmp"
              v-model="newImage"
              prepend-icon="mdi-camera"
              label="Themabord"
              @change="updateThemeImage"
            ></v-file-input>
            <v-card-actions>
              <v-btn @click="changeTheme()" v-if="lockTheme">Aanpassen</v-btn>
              <v-btn v-if="!lockTheme" @click="updateTheme()">Opslaan</v-btn>
              <v-btn v-if="!lockTheme" @click="lockTheme = true"
                >Annuleren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs12 class="ma-2" name="flexProgTabs" v-if="contentHome.prog">
          <v-card>
            <v-system-bar flat color="#ebb63b">
              <span>
                <b>Programma carrousel</b>
              </span></v-system-bar
            >
            <v-tabs v-model="tab" align-with-title color="#cc212f">
              <v-tabs-slider color="#cc212f"></v-tabs-slider>
              <v-tab v-for="(prog, i) in contentHome.prog" :key="i">
                {{ prog.name }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(prog, i) in contentHome.prog" :key="i">
                <v-card flat>
                  <v-row class="d-flex justify-space-around ma-3">
                    <img
                      v-if="prog.image"
                      :src="prog.foto"
                      width="80%"
                      contain
                    />
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
              <v-btn @click="changeCarrouselDialog = true"> aanpassen </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-dialog
      transition="dialog-top-transition"
      max-width="900"
      v-model="changeCarrouselDialog"
    >
      <v-card v-if="!buzzy">
        <v-system-bar class="pa-4" color="#ebb63b">
          <span>
            <b>Programma carrousel aanpassen</b>
          </span>
        </v-system-bar>
        <v-alert
          outlined
          color="#ebb63b"
          class="ma-5"
          v-for="(prog, i) in contentHome.prog"
          :key="i"
        >
          <div class="ma-3">
            <v-text-field
              v-model="prog.name"
              placeholder="(zondag, kindercarnaval, etc)"
              label="Naam van programma (zondag, kindercarnaval, etc)"
            >
            </v-text-field>
            <div v-if="prog.image">
              <img
                :src="prog.foto"
                contain
                width="80%"
                class="img-circle carrouselImage"
              />
              <v-btn text @click="deleteImage(prog.id), (prog.image = false)"
                >foto verwijderen</v-btn
              >
            </div>
            <div v-if="!prog.image">
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                v-model="newProgImage"
                prepend-icon="mdi-camera"
                @change="addImage(prog.id), (prog.image = true)"
              ></v-file-input>
            </div>
            <v-btn text @click="deleteProg(prog.id)"
              >Programma verwijderen<v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-alert>
        <v-icon class="ma-5" @click="addProg()" color="#cc212f"
          >mdi-plus</v-icon
        >
        <v-card-actions>
          <v-btn text @click="changeCarrouselDialog = false">Annuleren</v-btn>
          <v-btn text @click="updateCarrousel(), (buzzy = true)">Opslaan</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Carrousel aan het updaten....</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      lockIntro: true,
      newIntro: null,
      lockAgenda: true,
      changeCalendar: false,
      lockTheme: true,
      newThemeTitle: null,
      newThemeText: null,
      newThemeImage: null,
      newImage: null,
      newCalendarDate: {},
      calendarHeaders: [
        {
          text: "Datum",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Activiteit",
          sortable: false,
          value: "name",
        },
        {
          text: "Opmerkingen",
          sortable: false,
          value: "comment",
        },
        { sortable: false, value: "actions" },
      ],
      newProgImage: null,
      tab: null,
      changeCarrouselDialog: false,
      buzzy: false,
    };
  },
  computed: {
    homeIntro() {
      return this.$store.state.intros.home;
    },
    agenda() {
      return this.$store.state.intros.agenda;
    },
    themeTitle() {
      return this.$store.state.intros.themeTitle;
    },
    themeText() {
      return this.$store.state.intros.themeText;
    },
    themeImage() {
      return this.$store.state.intros.themeImage;
    },
    contentHome() {
      return this.$store.state.contentHome;
    },
  },
  methods: {
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.homeIntro;
    },
    changeAgenda() {
      this.lockAgenda = false;
      this.newAgenda = this.agenda;
    },
    changeTheme() {
      this.lockTheme = false;
      this.newThemeTitle = this.themeTitle;
      this.newThemeText = this.themeText;
      this.newThemeImage = this.themeImage;
    },
    updateThemeImage() {
      if (this.themeImage) {
        let storageRef = firebase.storage().ref();
        storageRef.child(`images/theme/${this.themeImage.id}`).delete();
      } else {
        this.newThemeImage = {};
      }
      let id = Date.now();
      this.newThemeImage.id = id;
      delete this.newThemeImage.url;

      let file = this.newImage;
      let file_type = file.type;
      if (file_type && file_type.substr(0, 5) == "image") {
        let img = document.createElement("img");
        let reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let MAX_WIDTH = 900;
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              let storageRef = firebase.storage().ref(`images/theme/${id}`);
              storageRef.put(blob);
            });
          };
        };
        reader.readAsDataURL(file);
      }
      let themeImage = {
        name: "themeImage",
        text: this.newThemeImage,
      };
      this.$store.dispatch("updateIntros", themeImage);
      setTimeout(() => {
        this.newImage = null;
        this.lockTheme = true;
      }, 2000);
    },
    deleteThemeImage() {
      let storageRef = firebase.storage().ref();
      storageRef.child(`images/theme/${this.themeImage.id}`).delete();
      this.newThemeImage = null;
      let themeImage = {
        name: "themeImage",
        text: this.newThemeImage,
      };
      this.$store.dispatch("updateIntros", themeImage);
    },
    updateIntro() {
      let intro = {
        name: "home",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
    updateAgenda() {
      let agenda = {
        name: "agenda",
        text: this.agenda,
      };
      this.$store.dispatch("updateIntros", agenda);
      setTimeout(() => {
        this.lockAgenda = true;
      }, 2000);
    },
    updateTheme() {
      let themeTitle = {
        name: "themeTitle",
        text: this.newThemeTitle,
      };
      let themeText = {
        name: "themeText",
        text: this.newThemeText,
      };

      this.$store.dispatch("updateIntros", themeTitle);
      this.$store.dispatch("updateIntros", themeText);
      setTimeout(() => {
        this.lockTheme = true;
      }, 2000);
    },
    addCalendarItem() {
      if (this.$store.state.intros.agenda) {
        this.$store.state.intros.agenda.push(this.newCalendarDate);
      } else {
        this.$store.state.intros.agenda = [];
        this.$store.state.intros.agenda.push(this.newCalendarDate);
      }
      this.newCalendarDate = {};
    },
    deleteCalendarItem(item) {
      let index = this.$store.state.intros.agenda.indexOf(item);
      this.$store.state.intros.agenda.splice(index, 1);
    },
    updateCarrousel() {
      this.$store.dispatch("updateContentHome");
      setTimeout(() => {
        this.changeCarrouselDialog = false;
        this.buzzy = false;
      }, 4000);
    },
    deleteImage(id) {
      let deleteRef = firebase.storage().ref(`images/home/carrouselProg/${id}`);
      deleteRef.delete();
    },
    addImage(id) {
      let file = this.newProgImage;
      let file_type = file.type;
      if (file_type && file_type.substr(0, 5) == "image") {
        let img = document.createElement("img");
        let reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let MAX_WIDTH = 300;
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              let storageRef = firebase
                .storage()
                .ref(`images/home/carrouselProg/${id}`);
              storageRef.put(blob);
            });
          };
        };
        reader.readAsDataURL(file);
      }
      this.newProgImage = null;
    },
    deleteProg(id) {
      for (let i = 0; i < this.contentHome.prog.length; i++) {
        if (this.contentHome.prog[i].id === id) {
          this.contentHome.prog.splice(i, 1);
        }
      }
      this.deleteImage(id);
    },
    addProg() {
      let prog = {
        id: Date.now(),
        image: false,
      };
      this.contentHome.prog.push(prog);
    },
  },
  beforeCreate() {
    this.$store.dispatch("loadContentHome");
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.headerClass {
  word-break: normal !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.v-list-item--active {
  color: red;
}
.img-circle {
  border-radius: 10%;
}
</style>