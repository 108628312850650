<template>
    <v-container>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Leden</b>
              </span>
            </v-system-bar>
            <v-card-text>
              <span v-for="member in preces" :key="member.id"
                ><b>{{ member.name }}</b
                ><br
              /></span>
              <span v-for="member in commissionMembers" :key="member.id"
                >{{ member.name }}<br />
              </span>
            </v-card-text>
          </v-card>
    </v-container>
</template>
<script>
    export default {
        name: "com-members",
        props: {
            preces: {
                type: Array,
                default: null,
            },
            commissionMembers: {
                type: Array,
                default: null,
            },
        },
    }
</script>