


export default {
  namespaced: true,
  state: {
    generalPermissions: [
        {
            id: "sec",
            name: "Secretariaat",
            locked: false,
            index: 1,
            children: [
                {
                    id: "secFin",
                    name: "Financiën",
                    locked: false,
                    index: 2
                },
                {
                    id: "secNotAge",
                    name: "Notulen en agenda's",
                    locked: false,
                    index: 1
                },
                {
                    id: "secjaarProg",
                    name: "Jaarprogramma",
                    locked: false,
                    index: 3
                },
                {
                    id: "secComlist",
                    name: "Commissielijst",
                    locked: false,
                    index: 4
                },
                {
                    id: "secLedlist",
                    name: "Ledenlijst",
                    locked: false,
                    index: 5
                },
                {
                    id: "secFotoLink",
                    name: "Foto bibliotheek",
                    locked: false,
                    index: 6
                },
                {
                    id: "secGebruikers",
                    name: "Gebruiker beheer",
                    locked: false,
                    index: 6
                },
            ]
        },
        {
            id: "clubAdmin",
            name: "Club van 111 administratie",
            locked: false,
            index: 2,
        },
      
        {
            id: "krant",
            name: "Krant",
            locked: false,
            index: 3,
            children: [
                {
                    id: "krantRed",
                    name: "Redactie",
                    locked: false,
                    index: 1
                },
                {
                    id: "krantAdv",
                    name: "Eigen advertenties",
                    locked: false,
                    index: 2
                }
            ]
        },
        {
            id: "web",
            name: "Websitebeheer",
            locked: false,
            index: 4,
            children: [
                {
                    id: "webNews",
                    name: "Nieuwsberichten",
                    locked: false,
                    index: 1
                },
                {
                    id: "webOptocht",
                    name: "Optocht",
                    locked: false,
                    index: 2
                },
                {
                    id: "webUBPF",
                    name: "UBPF",
                    locked: false,
                    index: 3
                },
                {
                    id: "webLiedjes",
                    name: "Liedjesfestival",
                    locked: false,
                    index: 4
                },
                {
                    id: "webHome",
                    name: "Homepage",
                    locked: false,
                    index: 5
                },
                {
                    id: "webJeugdraad",
                    name: "Jeugdraad",
                    locked: false,
                    index: 6
                },
                {
                    id: "webKrant",
                    name: "Carnevalskrant",
                    locked: false,
                    index: 7
                },
                {
                    id: "webkinder",
                    name: "Kindercarnaval",
                    locked: false,
                    index: 8
                },
                {
                    id: "webContact",
                    name: "Contact",
                    locked: false,
                    index: 9
                },
                {
                    id: "webHistory",
                    name: "Over ons",
                    locked: false,
                    index: 10
                }
            ]
        },
        {
            id: "stockAdmin",
            name: "Voorraadbeheer",
            locked: false,
            index: 5,
        },

    ]
  },
  getters: {
 
  },

  mutations: {
 



  },
  actions: {

  },
}