<template>
    <v-container>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Financieel overzicht</b>
                </span>
            </v-system-bar>
            <v-card-text>
                <v-row class="justify-center">
                    <v-radio-group class="ma-3" v-model="view" row color="#ebb63b">
                        <v-radio label="Controle gegevens" value="0"></v-radio>
                        <v-radio label="Facturatie overzicht" value="1"></v-radio>
                        <v-radio label="Contanten overzicht" value="2"></v-radio>
                    </v-radio-group>
                </v-row>
                <v-row class="justify-center">
                    <AdverteerderGegevensCheck class="ma-3" v-if="view == '0'"  />
                    <FacturatieOverview class="ma-3" v-if="view == '1'" />
                    <ContantenOverview class="ma-3" v-if="view == '2'" />

                </v-row>
            </v-card-text>

        </v-card>
    </v-container>
</template>
  
<script>
import AdverteerderGegevensCheck from './AdverteerderGegevensCheck.vue';
import FacturatieOverview from './FacturatieOverview.vue';
import ContantenOverview from './ContantenOverview.vue';

export default {
    name: "FinancialOverview",
    data() {
        return {
            view: "0",
        };
    },
    
    computed: {
        activeAdvertisements() {
            return this.$store.state.paperStore.activeAdvertisements;
        }
    },


    components: {  AdverteerderGegevensCheck, FacturatieOverview, ContantenOverview }
};
</script>
  
<style></style>