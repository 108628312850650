<template>
  <div>
    <v-card>
      <v-system-bar class="pa-4" color="#ebb63b">
        <span class="ahcRed subheading">
          <b>Deelnemende brouwerijen UBPF</b>
        </span>
        <v-spacer></v-spacer>
        <div class="d-flex" v-if="!addForm">
          <v-icon color="#cc212f" @click="addForm = true"
            >mdi-plus-outline</v-icon
          >
        </div>
      </v-system-bar>
      <v-form class="ma-3" v-if="addForm">
        <v-text-field
          v-model="newBrewery.name"
          label="Naam brouwerij"
          required
        ></v-text-field>
        <v-text-field
          v-model="newBrewery.website"
          label="website"
          placeholder="www.bier.nl"
          required
        ></v-text-field>
        <v-textarea
          v-model="newBrewery.intro"
          label="Introductie tekst"
          placeholder="Er was eens een brouwer..."
          required
        ></v-textarea>
        <v-btn
          class="ma-2"
          @click="saveNewBrewery"
          v-if="newBrewery.name && newBrewery.website && newBrewery.intro"
        >
          Opslaan
        </v-btn>
        <v-btn class="ma-2" @click="(newBrewery = {}), (addForm = false)">
          Annuleren
        </v-btn>
      </v-form>
      <div v-if="!addForm" id="breweyOverview">
        <div class="ma-3" v-for="brewery in breweries" :key="brewery.id">
          <v-divider></v-divider>
          <v-card-title>
            <span class="ahcRed subheading">
              <b>{{ brewery.name }}</b>
            </span>
            <v-spacer></v-spacer>
            <v-icon
              color="#cc212f"
              @click="(editBrewery = brewery), (editForm = true)"
              >mdi-pencil-outline</v-icon
            >
            <v-icon color="#cc212f" @click="deleteBrewery(brewery.id)"
              >mdi-trash-can-outline</v-icon
            >
          </v-card-title>
          <v-card-subtitle>{{ brewery.website }}</v-card-subtitle>
          <v-card-text>{{ brewery.intro }}</v-card-text>
          <v-divider></v-divider>
          <v-btn
            @click="
              (editBrewery = Object.assign({}, brewery)), (addBeerForm = true)
            "
            >Add beer</v-btn
          >
          <div class="mx-5" v-for="beer in brewery.beers" :key="beer.id">
            <v-card-title>
              <span class="ahcRed subheading">
                <b>{{ beer.name }}</b>
              </span>
              <v-spacer></v-spacer>
              <v-icon color="#cc212f" @click="deleteBeer(brewery, beer)"
                >mdi-trash-can-outline</v-icon
              >
            </v-card-title>
            <v-card-subtitle>
              Alcohol percentage: {{ beer.alcoholPercentage }}, IBU:
              {{ beer.IBU }}</v-card-subtitle
            >
            <v-card-text>{{ beer.intro }}</v-card-text>
          </div>
          <v-form class="ma-3" v-if="editForm">
            <v-text-field
              v-model="editBrewery.name"
              label="Naam brouwerij"
              required
            ></v-text-field>
            <v-text-field
              v-model="editBrewery.website"
              label="website"
              placeholder="www.bier.nl"
              required
            ></v-text-field>
            <v-textarea
              v-model="editBrewery.intro"
              label="Introductie tekst"
              placeholder="Er was eens een brouwer..."
              required
            ></v-textarea>
            <v-btn class="ma-2" @click="saveEditedBrewery">Opslaan</v-btn>
            <v-btn class="ma-2" @click="(editBrewery = {}), (editForm = false)"
              >Annuleren</v-btn
            >
          </v-form>
          <v-form class="ma-3" v-if="addBeerForm">
            <v-text-field
              v-model="newBeer.name"
              label="Naam bier"
              required
            ></v-text-field>
            <v-text-field
              v-model="newBeer.alcoholPercentage"
              label="Alcoholpercentage"
              required
            ></v-text-field>
            <v-text-field
              v-model="newBeer.IBU"
              label="IBU"
              required
            ></v-text-field>
            <v-textarea
              v-model="newBeer.intro"
              label="Introductie tekst"
            ></v-textarea>
            <v-btn class="ma-2" @click="saveNewBeer">Opslaan</v-btn>
            <v-btn class="ma-2" @click="(newBeer = {}), (addBeerForm = false)"
              >Annuleren</v-btn
            >
          </v-form>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addForm: false,
      newBrewery: {},
      editBrewery: {},
      editForm: false,
      addBeerForm: false,
      newBeer: {},
    };
  },
  methods: {
    saveNewBrewery() {
      this.newBrewery.changeLog = [];
      this.newBrewery.id = Date.now();
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let createdMsg = `Brouwerij is aangemaakt door ${this.currentUser.name} op ${creationDate}`;
      this.newBrewery.creater = this.currentUser.name;
      this.newBrewery.beers = [];
      this.newBrewery.changeLog.push(createdMsg);
      this.$store.dispatch("addBrewery", { newBrewery: this.newBrewery });
      this.newBrewery = {};
      this.addForm = false;
    },
    saveEditedBrewery() {
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let str = `De gegevens zijn aangepast door ${this.currentUser.name} op ${creationDate}`;
      this.editBrewery.changeLog.push(str);
      this.$store.dispatch("saveEditedBrewery", {
        newBrewery: this.editBrewery,
      });
      for (let i = 0; i < this.breweries.length; i++) {
        if (this.breweries[i].id == this.editBrewery.id) {
          this.breweries[i] = this.editBrewery;
        }
      }
      this.editBrewery = {};
      this.editForm = false;
    },
    deleteBrewery(breweryId) {
      this.$store.dispatch("deleteBrewery", { breweryId: breweryId });
    },
    saveNewBeer() {
      this.newBeer.id = Date.now();
      this.editBrewery.beers.push(this.newBeer);
      this.saveEditedBrewery();
      this.newBeer = {};
      this.addBeerForm = false;
    },
    deleteBeer(brewery, beer) {
      let index;
      for (let i = 0; i < brewery.beers.length; i++) {
        if (brewery.beers[i].id == beer.id) {
          index = i;
        }
      }
      brewery.beers.splice(index, 1);
      this.$store.dispatch("saveEditedBrewery", { newBrewery: brewery });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    breweries() {
      return this.$store.state.breweries;
    },
  },
  created() {
    this.$store.dispatch("loadBreweries");
  },
};
</script>

<style>
</style>