<template>
    <v-container>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Redactie overzicht</b>
                </span>
            </v-system-bar>
            <v-card-text>
                <v-row>
                    <TotalenAdvertenties class="ma-3" :advertisers="advertisers" :advertisements="activeAdvertisements" />
                </v-row>
                <v-row class="justify-center">
                    <v-radio-group class="ma-3" v-model="view" row color="#ebb63b">
                        <v-radio label="Te verwerken advertenties" value="0"></v-radio>
                        <v-radio label="Te plaatsen advertenties (Alleen eind redactie!)" value="1"></v-radio>
                        <v-radio label="Formaat 8,5" value="2"></v-radio>
                        <v-radio label="Formaat 17" value="3"></v-radio>
                        <v-radio label="Formaat 25,9" value="4"></v-radio>
                    </v-radio-group>
                </v-row>
                <v-row class="justify-center">
                    <AdvertentieOverview class="ma-3" v-if="view == '0'" :redactionPage="'editing'" />
                    <AdvertentieOverview class="ma-3" v-if="view == '1'" :redactionPage="'placement'" />
                    <FormatOverview class="ma-3" v-if="view == '2'" :format="0" />
                    <FormatOverview class="ma-3" v-if="view == '3'" :format="1" />
                    <FormatOverview class="ma-3" v-if="view == '4'" :format="2" />
                </v-row>
            </v-card-text>

        </v-card>
    </v-container>
</template>
  
<script>
import AdvertentieOverview from './AdvertentieOverview.vue';
import FormatOverview from './FormatOverview.vue';
import TotalenAdvertenties from './TotalenAdvertenties.vue';

export default {
    name: "RedactieOverview",
    data() {
        return {
            view: "0",
        };
    },
    props: {
        advertisers: {
            type: Array,
            required: true,
        },
    },
    computed: {
        activeAdvertisements() {
            return this.$store.state.paperStore.activeAdvertisements;
        }
    },


    components: { TotalenAdvertenties, AdvertentieOverview, FormatOverview }
};
</script>
  
<style></style>