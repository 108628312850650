var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',{staticClass:"ma-2 justify-space-between",attrs:{"row":"","wrap":"","xs12":""}},[_c('h1',{staticClass:"ma-4 d-flex"},[_c('span',{staticClass:"ahcRed"},[_vm._v("Jaarprogramma")])]),_c('v-spacer'),(
        _vm.currentUser && _vm.currentUser.role == 'Admin' && _vm.editCalendar == false
      )?_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"color":"#ebb63b"},on:{"click":function($event){(_vm.editCalendar = true), (_vm.change = true)}}},[_vm._v("mdi-pencil-outline")])],1):_vm._e(),(
        _vm.currentUser && _vm.currentUser.role == 'Admin' && _vm.editCalendar == true
      )?_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"color":"#ebb63b"},on:{"click":function($event){(_vm.editCalendar = false), _vm.saveCalendar(), (_vm.change = false)}}},[_vm._v("mdi-content-save-edit")])],1):_vm._e()],1),_c('v-layout',{staticClass:"ma-3",attrs:{"row":"","wrap":"","name":"layoutCalendarModule"}},[_c('v-flex',{attrs:{"xs12":"","name":"flexCalendar"}},[_c('v-flex',{staticClass:"ma-2"},[_c('v-card',[_c('v-system-bar',{staticClass:"pa-4",attrs:{"color":"#ebb63b"}}),_c('v-card-text',[(_vm.editCalendar)?_c('v-form',{staticClass:"ma-5 d-flex flex-row",attrs:{"align":"center"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.newDate.date),callback:function ($$v) {_vm.$set(_vm.newDate, "date", $$v)},expression:"newDate.date"}}),_c('v-text-field',{staticClass:"grey--text",attrs:{"label":"Naam"},model:{value:(_vm.newDate.name),callback:function ($$v) {_vm.$set(_vm.newDate, "name", $$v)},expression:"newDate.name"}}),_c('v-text-field',{staticClass:"grey--text",attrs:{"label":"Opmerking"},model:{value:(_vm.newDate.comment),callback:function ($$v) {_vm.$set(_vm.newDate, "comment", $$v)},expression:"newDate.comment"}}),_c('div',[_c('v-icon',{attrs:{"color":"#ebb63b"},on:{"click":_vm.addItem}},[_vm._v("mdi-plus-circle")])],1)],1):_vm._e(),[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.calendar,"sortBy":"date","hide-default-footer":"","loading-text":"Het jaarprogramma wordt ingeladen","disable-pagination":""},scopedSlots:_vm._u([(
                    _vm.currentUser &&
                    _vm.currentUser.role == 'Admin' &&
                    _vm.editCalendar == true
                  )?{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"ml-5",attrs:{"color":"#cc212f"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-minus-circle ")])]}}:null],null,true)})]],2)],1)],1)],1)],1),(_vm.calendar.length == 0 && _vm.loadingTimer)?_c('v-card',{staticClass:"ma-5",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"d-flex justify-space-around mb-6"},[_c('v-progress-circular',{staticClass:"ma-5 ahcRed",attrs:{"size":70,"width":7,"indeterminate":""}})],1),_c('v-row',{staticClass:"d-flex justify-space-around mb-6"},[_c('h3',[_vm._v("Inladen jaarprogramma...")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }