<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Contact</span>
      </h1>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutEditModules">
      <v-flex xs12 class="ma-3" name="flexIntro">
        <v-card>
          <v-system-bar class="pa-4" color="#ebb63b">
            <span class="ahcRed">
              <b>Introductie tekst Contactpage</b>
            </span>
          </v-system-bar>
          <v-textarea class="pa-2" v-model="contactIntro" disabled v-if="lockIntro"></v-textarea>
          <v-textarea class="pa-2" v-model="newIntro" v-if="!lockIntro"></v-textarea>
          <v-card-actions>
            <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
            <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
            <v-btn v-if="!lockIntro" @click="lockIntro = true">Annuleren</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 class="ma-3" name="flexContactAdresses">
        <v-card>
          <v-system-bar class="pa-4" color="#ebb63b">
            <span class="ahcRed">
              <b>Gelinkte adressen contactformulier</b>
            </span>
          </v-system-bar>
          <v-card-text v-if="contactAdresses">
            <span>
              <b>Bij blanco wordt het formulier automatisch naar info@.. gestuurd</b>
            </span>
            <br />
            <br />
            <v-layout row class="d-flex align-center">
              <v-flex xs11>
                <span class="ahcGreen" v-if="!editAlgemeen">
                  Algemeen:
                  <b>{{contactAdresses.Algemeen}}</b>
                </span>
                <v-text-field
                  v-if="editAlgemeen"
                  v-model="contactAdresses.Algemeen"
                  label="emailadres algemeen"
                  class="ahcGreen"
                  :rules="[v => /.+@.+\..+/.test(v) || 'E-mail voldoet niet aan het juiste formaat']"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon
                  v-if="!editAlgemeen"
                  class="ma-3"
                  @click="editAlgemeen = !editAlgemeen"
                >mdi-pencil</v-icon>
                <v-icon
                  v-if="editAlgemeen"
                  class="ma-3"
                  @click="editAlgemeen = !editAlgemeen, editContactAdresses()"
                >mdi-content-save-edit</v-icon>
              </v-flex>
            </v-layout>
            <v-layout row class="d-flex align-center">
              <v-flex xs11>
                <span class="ahcGreen" v-if="!editUBPF">
                  UBPF:
                  <b>{{contactAdresses['Ulster bierproeffestival']}}</b>
                </span>
                <v-text-field
                  v-if="editUBPF"
                  v-model="contactAdresses['Ulster bierproeffestival']"
                  label="emailadres UBPF"
                  class="ahcGreen"
                  :rules="[v =>  /.+@.+\..+/.test(v) || 'E-mail voldoet niet aan het juiste formaat']"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon v-if="!editUBPF" class="ma-3" @click="editUBPF = !editUBPF">mdi-pencil</v-icon>
                <v-icon
                  v-if="editUBPF"
                  class="ma-3"
                  @click="editUBPF = !editUBPF, editContactAdresses()"
                >mdi-content-save-edit</v-icon>
              </v-flex>
            </v-layout>
            <v-layout row class="d-flex align-center">
              <v-flex xs11>
                <span class="ahcGreen" v-if="!editLiedjes">
                  Liedjesfestival:
                  <b>{{contactAdresses.Liedjesfestival}}</b>
                </span>
                <v-text-field
                  v-if="editLiedjes"
                  v-model="contactAdresses.Liedjesfestival"
                  label="emailadres liedjesfestival"
                  class="ahcGreen"
                  :rules="[v => /.+@.+\..+/.test(v) || 'E-mail voldoet niet aan het juiste formaat']"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon
                  v-if="!editLiedjes"
                  class="ma-3"
                  @click="editLiedjes = !editLiedjes"
                >mdi-pencil</v-icon>
                <v-icon
                  v-if="editLiedjes"
                  class="ma-3"
                  @click="editLiedjes = !editLiedjes, editContactAdresses()"
                >mdi-content-save-edit</v-icon>
              </v-flex>
            </v-layout>
            <v-layout row class="d-flex align-center">
              <v-flex xs11>
                <span class="ahcGreen" v-if="!editKrant">
                  Krant:
                  <b>{{contactAdresses.Krant}}</b>
                </span>
                <v-text-field
                  v-if="editKrant"
                  v-model="contactAdresses.Krant"
                  label="emailadres krant"
                  class="ahcGreen"
                  :rules="[v => /.+@.+\..+/.test(v) || 'E-mail voldoet niet aan het juiste formaat']"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon v-if="!editKrant" class="ma-3" @click="editKrant = !editKrant">mdi-pencil</v-icon>
                <v-icon
                  v-if="editKrant"
                  class="ma-3"
                  @click="editKrant = !editKrant, editContactAdresses()"
                >mdi-content-save-edit</v-icon>
              </v-flex>
            </v-layout>
            <v-layout row class="d-flex align-center">
              <v-flex xs11>
                <span class="ahcGreen" v-if="!editClub111">
                  Club van 111:
                  <b>{{contactAdresses['Club van 111']}}</b>
                </span>
                <v-text-field
                  v-if="editClub111"
                  v-model="contactAdresses['Club van 111']"
                  label="emailadres club van 111"
                  class="ahcGreen"
                  :rules="[v => /.+@.+\..+/.test(v) || 'E-mail voldoet niet aan het juiste formaat']"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon
                  v-if="!editClub111"
                  class="ma-3"
                  @click="editClub111 = !editClub111"
                >mdi-pencil</v-icon>
                <v-icon
                  v-if="editClub111"
                  class="ma-3"
                  @click="editClub111 = !editClub111, editContactAdresses()"
                >mdi-content-save-edit</v-icon>
              </v-flex>
            </v-layout>
            <v-layout row class="d-flex align-center">
              <v-flex xs11>
                <span class="ahcGreen" v-if="!editWebshop">
                  Webshop:
                  <b>{{contactAdresses.Webshop}}</b>
                </span>
                <v-text-field
                  v-if="editWebshop"
                  v-model="contactAdresses.Webshop"
                  label="emailadres webshop"
                  class="ahcGreen"
                  :rules="[v => /.+@.+\..+/.test(v) || 'E-mail voldoet niet aan het juiste formaat']"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon
                  v-if="!editWebshop"
                  class="ma-3"
                  @click="editWebshop = !editWebshop"
                >mdi-pencil</v-icon>
                <v-icon
                  v-if="editWebshop"
                  class="ma-3"
                  @click="editWebshop = !editWebshop, editContactAdresses()"
                >mdi-content-save-edit</v-icon>
              </v-flex>
            </v-layout>
            <v-layout row class="d-flex align-center">
              <v-flex xs11>
                <span class="ahcGreen" v-if="!editOptocht">
                  Optocht:
                  <b>{{contactAdresses.Optocht}}</b>
                </span>
                <v-text-field
                  v-if="editOptocht"
                  v-model="contactAdresses.Optocht"
                  label="emailadres optocht"
                  class="ahcGreen"
                  :rules="[v => /.+@.+\..+/.test(v) || 'E-mail voldoet niet aan het juiste formaat']"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-icon
                  v-if="!editOptocht"
                  class="ma-3"
                  @click="editOptocht = !editOptocht"
                >mdi-pencil</v-icon>
                <v-icon
                  v-if="editOptocht"
                  class="ma-3"
                  @click="editOptocht = !editOptocht, editContactAdresses()"
                >mdi-content-save-edit</v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      lockIntro: true,
      newIntro: null,
      editAlgemeen: false,
      editUBPF: false,
      editLiedjes: false,
      editKrant: false,
      editClub111: false,
      editWebshop: false,
      editOptocht: false
    };
  },
  computed: {
    contactIntro() {
      return this.$store.state.intros.contact;
    },
    contactAdresses() {
      return this.$store.state.contactAdresses;
    }
  },
  methods: {
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.homeIntro;
    },
    updateIntro() {
      let intro = {
        name: "contact",
        text: this.newIntro
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
    editContactAdresses() {
      this.$store.dispatch("editContactAdresses", this.contactAdresses);
    }
  },
  created() {
    this.$store.dispatch("getContactAdresses");
  }
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.headerClass {
  word-break: normal !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.v-list-item--active {
  color: red;
}
</style>