<template>
    <div>
        <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
                <span class="ahcRed subheading">
                    <b>Boekhouding</b>
                </span>
            </v-system-bar>
            <v-tabs color="#cc212f" v-if="accountancy">
                <v-tab v-model="yearTab" v-for="(year, i) in accountancy.years" :key="i">{{ year.title }}</v-tab>
                <v-tab-item v-for="(year, i) in accountancy.years" :key="i">
                    <v-card-text>
                        <v-alert outlined color="#ebb63b">
                            <v-flex xs12 class="ma-2">
                                <v-row>
                                    <v-col>
                                        <v-card></v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat>
                                            <h3>Uitgaven</h3>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat>
                                            <h3>Inkomsten</h3>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat>
                                            <h3>Resultaat</h3>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card flat>
                                            <h3>Begroot</h3>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">€{{
                                            getTotalValue(year, "expencesBudget").toFixed(2)
                                                }}</v-chip></v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">€{{
                                            getTotalValue(year, "incomeBudget").toFixed(2)
                                                }}</v-chip></v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">€{{
                                            (
                                                getTotalValue(year, "incomeBudget")
                                                -
                                                getTotalValue(year, "expencesBudget")
                                            ).toFixed(2)
                                        }}</v-chip></v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card flat>
                                            <h3>Werkelijk</h3>
                                            <small>(Tot op heden)</small>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">€{{
                                            getTotalValue(year, "expences").toFixed(2)
                                                }}</v-chip></v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">€{{
                                            getTotalValue(year, "income").toFixed(2)
                                                }}</v-chip></v-card>
                                    </v-col>
                                    <v-col>
                                        <v-card flat><v-chip color="#ebb63b" text-color="#cc212f">€{{
                                            (
                                                getTotalValue(year, "income")
                                                -
                                                getTotalValue(year, "expences")
                                            ).toFixed(2)
                                        }}</v-chip></v-card>
                                    </v-col>
                                </v-row>
                            </v-flex>
                        </v-alert>
                        <v-card class="my-2">
                            <v-system-bar class="pa-4">
                                <span>
                                    <b>Transacties</b>
                                </span>
                            </v-system-bar>
                            <v-expansion-panels flat>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <span><b>Openstaande declaraties</b></span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content v-if="year.workgroups[commissionIndex- 1]">
                                        <v-list v-if="
                                             year.workgroups[commissionIndex- 1]
                                                .declarations.open.length > 0
                                        " two line>
                                            <v-list-item v-for="(transaction, k) in year.workgroups[
                                                commissionIndex - 1
                                            ].declarations.open" :key="k">
                                                <v-list-item-icon v-if="transaction.amount < 0"><v-icon
                                                        color="red">mdi-arrow-down-bold</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-icon v-if="transaction.amount > 0"><v-icon
                                                        color="green">mdi-arrow-up-bold</v-icon></v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title>€{{ transaction.amount.toFixed(2) }}
                                                        {{
                                                            transaction.description
                                                        }}</v-list-item-title>

                                                    <v-list-item-subtitle><small>{{ transaction.creationDate }} -
                                                            {{ transaction.creator }}</small></v-list-item-subtitle>
                                                </v-list-item-content>

                                                <v-list-item-icon v-if="transaction.receiptType" @click="
                                                    downloadReceipt(
                                                        transaction.id,
                                                        transaction.receiptType
                                                    )
                                                    ">
                                                    <v-icon>mdi-file-download-outline</v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        <span><b>Afgesloten declaraties</b></span>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content v-if="year.workgroups[commissionIndex - 1]">
                                        <v-list v-if="
                                            year.workgroups[commissionIndex - 1]
                                                .declarations.closed.length > 0
                                        " three line>
                                            <v-list-item v-for="(transaction, k) in year.workgroups[
                                                commissionIndex - 1
                                            ].declarations.closed" :key="k">
                                                <v-list-item-icon v-if="
                                                    transaction.amount < 0 && !transaction.reason
                                                "><v-icon color="red">mdi-arrow-down-bold</v-icon>
                                                </v-list-item-icon>
                                                <v-list-item-icon v-if="
                                                    transaction.amount > 0 && !transaction.reason
                                                "><v-icon color="green">mdi-arrow-up-bold</v-icon></v-list-item-icon>
                                                <v-list-item-icon v-if="transaction.reason"><v-icon
                                                        color="red">mdi-close-thick</v-icon></v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>€{{ transaction.amount.toFixed(2) }}
                                                        {{
                                                            transaction.description
                                                        }}</v-list-item-title>
                                                    <v-list-item-subtitle v-if="transaction.reason"><small>{{
                                                        transaction.reason }}
                                                        </small></v-list-item-subtitle>
                                                    <v-list-item-subtitle><small>{{ transaction.creationDate }} -
                                                            {{ transaction.creator }}</small></v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-icon v-if="transaction.receiptType" @click="
                                                    downloadReceipt(
                                                        transaction.id,
                                                        transaction.receiptType
                                                    )
                                                    ">
                                                    <v-icon>mdi-file-download-outline</v-icon>
                                                </v-list-item-icon>
                                            </v-list-item>
                                        </v-list>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </v-card-text>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>
<script>
import firebase from "firebase";
import Axios from "axios";
export default {
    name: "workgroup-financials",
    props: {
        commissionIndex: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            yearTab: 0,
        };
    },
    computed: {
        accountancy() {
            return this.$store.state.accountancy;
        },
    },
    methods: {
        getTotalValue(year, type) {
            try {
                let total = 0;
                let index = year?.workgroups?.findIndex((workgroup) => workgroup.index === this.commissionIndex.toString()); 
                if(index > -1){
                    total = parseFloat(year.workgroups[index][type]);
                }
                
                return total;
            } catch (error) {
                console.log(error.message);
            }
        },
        downloadReceipt(id, file_type) {
            let metadata;
            if (file_type === "doc" || file_type === "dot") {
                metadata = "application/msword";
            } else if (file_type === "docx") {
                metadata =
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            } else if (
                file_type === "xls" ||
                file_type === "xlt" ||
                file_type === "xla"
            ) {
                metadata = "application/vnd.ms-excel";
            } else if (file_type === "xlsx") {
                metadata =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            } else if (
                file_type === "ppt" ||
                file_type === "pot" ||
                file_type === "pps" ||
                file_type === "ppa"
            ) {
                metadata = "application/vnd.ms-powerpoint";
            } else if (file_type === "pptx") {
                metadata =
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            } else if (file_type === "mdb") {
                metadata = "application/vnd.ms-access";
            } else if (file_type === "pdf") {
                metadata = "application/pdf";
            } else if (file_type === "png") {
                metadata = "image/png";
            } else if (
                file_type === "jpg" ||
                file_type === "jpeg" ||
                file_type === "JPG" ||
                file_type === "JPEG"
            ) {
                metadata = "image/jpeg";
            }

            let filename = `Factuur_C${this.commissionIndex}_${id}`;
            let file = firebase
                .storage()
                .ref(`accountancy/C${this.commissionIndex}/${id}`);
            file.getDownloadURL().then((url) => {
                Axios.get(url, { responseType: "blob" })
                    .then(({ data }) => {
                        const blob = new Blob([data], {
                            type: metadata,
                        });

                        const url = document.createElement("a");
                        url.href = URL.createObjectURL(blob);
                        url.download = filename;
                        url.click();
                        URL.revokeObjectURL(url.href);
                    })
                    .catch((error) => alert(error));
            });
        },
    },
}

</script>