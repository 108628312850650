<template>
    <v-container>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" class="ma-5" single-line
            hide-details></v-text-field>
        <v-data-table
            :headers="redactionPage == 'notSold' ? notSoldHeaders : redactionPage == 'open' ? openHeaders : headers"
            :items="openAds" class="elevation-1" disable-pagination hide-default-footer show-expand
            :single-expand="singleExpand" :expanded.sync="expanded" :search="search" sort-by="advertiserUserId">
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-alert outlined color="#ebb63b">
                        <v-card flat>
                            <h4 v-if="redactionPage == 'placement'">Plaatsing</h4>
                            <v-row v-if="redactionPage == 'placement'" class="d-flex justify-space-around ma-3">
                                <v-col>
                                    <v-text-field label="geplaatst op pagina: " v-model="item.placedOnPage"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-btn @click="saveAdvertisement(item)">opslaan</v-btn>
                                </v-col>
                            </v-row>
                            <v-divider v-if="redactionPage == 'placement'"></v-divider>
                            <h4>Gegevens</h4>


                            <v-row class="d-flex justify-space-around ma-3">
                                <v-col>
                                    <span>Bedrijfsnaam: <b>{{ item.name }}</b></span>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex justify-space-around ma-3"><v-col>
                                    <span>Status: <b>{{ item.advState }}</b></span>
                                </v-col></v-row>

                            <v-row class="d-flex justify-space-around ma-3">
                                <v-col>
                                    <span>Prijs: <b>{{ item.advPrice }}</b></span>
                                </v-col>


                            </v-row>
                            <v-row class="d-flex justify-space-around ma-3">
                                <v-col>
                                    <span>Formaat: <b>{{ item.advFormat }}</b></span>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex justify-space-around ma-3">
                                <v-col>
                                    <span>Betaalmethode: <b>{{ item.advPaymentMethod }}</b></span>
                                </v-col>

                            </v-row>
                            <v-row class="d-flex justify-space-around ma-3">
                                <v-col>
                                    <span>Aanpassingen: <b>{{ item.advType }}</b></span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h4>Opmerkingen</h4>
                            <v-row class="d-flex justify-space-around ma-3">
                                <v-col>
                                    <span>{{ item.advNotes }}</span>

                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <h4>Documenten</h4>
                            <v-row class="d-flex justify-space-around ma-3">
                                <span v-if="!item.docs || item.docs.length == 0">
                                    Er zijn voor deze advertentie geen documenten geupload.
                                </span>
                                <v-list v-if="item.docs && item.docs.length > 0" three-line>
                                    <v-list-item v-for="(doc, i) in item.docs" :key="i">
                                        <v-list-item-icon
                                            @click="downloadFile(doc.title, doc.id, doc.type, item.advertiserUserId)">
                                            <v-icon>mdi-file-download-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ doc.title }}</v-list-item-title>
                                            <v-list-item-subtitle v-if="doc.description">{{
                                                doc.description
                                            }}</v-list-item-subtitle>
                                            <v-list-item-subtitle><small>{{ doc.creationDate }} - {{ doc.creator
                                            }}</small></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <!-- <v-list-item-icon @click="deleteDoc(doc.id, item.advertiserUserId)">
                                                        <v-icon>mdi-delete-outline</v-icon>
                                                    </v-list-item-icon> -->
                                    </v-list-item>
                                </v-list>
                            </v-row>

                            <div>
                                <v-divider></v-divider>
                                <h4 class="mb-3">Communicatie binnen redactie</h4>
                                <v-row v-for="(note, i) in item.advRedactieNotes" :key="i">
                                    <span class="mx-3">
                                        {{ note }}

                                    </span>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field v-model="redactionNote" label="Interne opmerking"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-btn color="#ebb63b" @click="addRedactionNote(item)">Voeg opmerking toe</v-btn>
                                    </v-col>
                                </v-row>

                            </div>
                            <div>
                                <v-divider></v-divider>
                                <h4 class="mb-3">Vragen aan verkoper</h4>
                                <div class="ma-3" v-for="(question, i) in item.advContactQuestions" :key="i">
                                    <v-row >
                                    <span class="mx3">{{ question.msg }} - {{ question.status }}</span>
                                   
                                </v-row>
                                <v-row>
                                    <v-btn   v-if="question.status == 'open'"
                                        @click="markAsFulfilled(item, i)"><v-icon>mdi-thumb-up</v-icon>opgelost</v-btn>
                                </v-row>

                                </div>
                                
                                <v-row v-if="redactionPage === 'initial' || 'open' || 'notSold'">
                                    <v-col>
                                        <v-text-field v-model="contactQuestion" label="Vraag aan verkoper"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-btn color="#ebb63b" @click="addContactQuestion(item)">Stel vraag</v-btn>
                                    </v-col>
                                </v-row>

                            </div>



                        </v-card>
                    </v-alert>
                </td>
            </template>

            <template v-slot:[`item.openQuestion`]="{ item }">
                <v-icon v-if="item.openQuestion">mdi-exclamation-thick </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip v-if="redactionPage !== 'notSold' && redactionPage !== 'placement' && redactionPage !== 'open'"
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="promoteItem(item)">
                            mdi-sort-bool-ascending
                        </v-icon>
                    </template>
                    <span>{{ redactionPage == 'initial' ? 'Klaar voor redactie' : 'Klaar voor plaatsing'
                    }}</span>
                </v-tooltip>
                <v-tooltip v-if="redactionPage !== 'initial' && redactionPage !== 'notSold' && redactionPage !== 'open'"
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="demoteItem(item)">
                            mdi-sort-bool-descending
                        </v-icon>
                    </template>
                    <span>{{ redactionPage == 'editing' ? 'Advertentie nog niet compleet' : 'Advertentie nog niet klaar voor plaatsing'
                    }}</span>
                </v-tooltip>
            </template>
        </v-data-table>


    </v-container>
</template>
<script>

import firebase from "firebase";
import Axios from "axios";
export default {
    name: "TotalenAdvertenties",
    props: {
        redactionPage: {
            type: String,
            default: "initial"
        },
        selectedEdition: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            onlyOpenAds: false,
            search: "",
            singleExpand: false,
            contactQuestion: "",
            expanded: [],
            redactionNote: "",
            headers: [
                { text: 'nr', value: 'advertiserUserId', align: 'start' },
                { text: 'Bedrijfsnaam', value: 'name' },
                { text: 'Status', value: 'advState' },
                { text: 'Verkoper', value: 'contact' },
                { text: 'Geplaatst', value: 'placedOnPage' },
                { text: 'uitstaande vraag', value: 'openQuestion' },
                { text: 'promote/demote', value: 'actions' },
                { text: "", value: "data-table-expand" },
            ],
            openHeaders: [
                { text: 'nr', value: 'advertiserUserId', align: 'start' },
                { text: 'Bedrijfsnaam', value: 'name' },
                { text: 'Status', value: 'advState' },
                { text: 'Verkoper', value: 'contact' },
                { text: 'Geplaatst', value: 'placedOnPage' },
                { text: "", value: "data-table-expand" },
            ],
            notSoldHeaders: [
                { text: 'nr', value: 'advertiserUserId', align: 'start' },
                { text: 'Bedrijfsnaam', value: 'name' },
                { text: 'Status', value: 'advState' },
                { text: 'Verkoper', value: 'contact' },
                { text: 'Geplaatst', value: 'placedOnPage' },
                { text: "", value: "data-table-expand" },
            ]
        }
    },
    computed: {
        advertisers() {
            return this.$store.state.paperStore.advertisers;
        },
        activeAdvertisements() {
            return this.$store.state.paperStore.activeAdvertisements;
        },
        archiveAdvertisements(){
            return this.$store.state.paperStore.archiveAdvertisements;
        },
        relevantAdvertisements(){
            if(this.selectedEdition == null || this.selectedEdition == this.$store.state.paperStore.activeEditionId){
                return this.activeAdvertisements ?? [];
            } else {
                let ads = []
                this.archiveAdvertisements.forEach(edition => {
                    if(edition.id == this.selectedEdition){
                        ads = edition.ads
                    }
                });
                return ads;
            }
        },
        activeEdition() {
            let activeEditionId = this.$store.state.paperStore.activeEditionId;
            let editions = this.$store.state.paperStore.paperEditions;
            return editions.find(edition => edition.id == activeEditionId);
        },
        userName() {
            return this.$store.state.currentUser.name;
        },
        allAds() {
            const generateUUID = () => {
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
            }
            let ads = [];
            this.advertisers.forEach(advertiser => {
                let ad = this.relevantAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
                if (!ad) {
                    ad = {
                        id: generateUUID(),
                        advertiserId: advertiser.id,
                        name: advertiser.name,
                        advertiserUserId: advertiser.userId,
                        editionId: advertiser.id,
                        advState: "open",
                        advRedactieNotes: [],
                        placedOnPage: "",
                        readyForPlacement: false,
                        readyForEditing: false,
                        placed: false,
                        contact: advertiser.contact,
                        docs: [],
                    };
                }
                ads.push(ad);
            });
            return ads
        },

        openAds() {
            let openAds = [];
            this.allAds.forEach(ad => {
                switch (this.redactionPage) {
                    case "notSold":
                        if (ad.advState === "niet verkocht") {
                            openAds.push(ad);
                        }
                        break;
                    case "open":
                        if (ad.advState === "open") {
                            openAds.push(ad);
                        }
                        break;
                    case "initial":
                        if (!ad.readyForEditing && ad.advState == "verkocht") {
                            openAds.push(ad);
                        }
                        break;
                    case "editing":
                        if (ad.readyForEditing && !ad.readyForPlacement && ad.advStae !== "niet verkocht") {
                            openAds.push(ad);
                        }
                        break;
                    case "placement":
                        if (ad.readyForPlacement && ad.advState !== "niet verkocht") {
                            openAds.push(ad);
                        }
                        break;
                    default:
                        break;
                }
            });
            return openAds;
        },
    },
    methods: {
        downloadFile(title, id, file_type, parentUserID) {
            let metadata;
            if (file_type === ("doc" || "dot")) {
                metadata = "application/msword";
            } else if (file_type === "docx") {
                metadata =
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            } else if (file_type === ("xls" || "xlt" || "xla")) {
                metadata = "application/vnd.ms-excel";
            } else if (file_type === "xlsx") {
                metadata =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
                metadata = "application/vnd.ms-powerpoint";
            } else if (file_type === "pptx") {
                metadata =
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
            } else if (file_type === "mdb") {
                metadata = "application/vnd.ms-access";
            } else if (file_type === "pdf") {
                metadata = "application/pdf";
            } else if (file_type == "jpg") {
                metadata = "image/jpeg";
            } else if (file_type == "png") {
                metadata = "image/png";
            } else if (file_type == "gif") {
                metadata = "image/gif";
            } else if (file_type == "txt") {
                metadata = "text/plain";
            }

            let filename = title;
            let file = firebase
                .storage()
                .ref(`docs/Edition_${this.activeEdition.title}/${parentUserID}/${id}`);
            file.getDownloadURL().then((url) => {
                Axios.get(url, { responseType: "blob" })
                    .then(({ data }) => {
                        const blob = new Blob([data], {
                            type: metadata,
                        });

                        const url = document.createElement("a");
                        url.href = URL.createObjectURL(blob);
                        url.download = filename;
                        url.click();
                        URL.revokeObjectURL(url.href);
                    })
                    .catch((error) => alert(error));
            });
        },
        deleteDoc(id, parentUserID) {
            for (let i = 0; i < this.advertisement.docs.length; i++) {
                if (this.advertisement.docs[i].id === id) {
                    let storageRef = firebase.storage().ref();
                    storageRef
                        .child(`docs/Edition_${this.activeEdition.title}/${parentUserID}/${id}`)
                        .delete();

                    this.advertisement.docs.splice(i, 1);
                    this.$store.commit("paperStore/saveAdvertisement", {
                        advertisement: this.advertisement,
                    });
                }
            }

        },

        promoteItem(item) {
            switch (this.redactionPage) {
                case "initial":
                    item.readyForEditing = true;
                    break;
                case "editing":
                    item.readyForPlacement = true;
                    break;
                default:
                    break;
            }
            this.saveAdvertisement(item);
        },
        demoteItem(item) {
            switch (this.redactionPage) {
                case "editing":
                    item.readyForEditing = false;
                    break;
                case "placement":
                    item.readyForPlacement = false;
                    break;
                default:
                    break;
            }
            this.saveAdvertisement(item);
        },
        addRedactionNote(item) {
            if (this.redactionNote.length > 0) {
                if (!item.advRedactieNotes) item.advRedactieNotes = [];
                //add username and date
                item.advRedactieNotes.push(this.redactionNote + " - " + this.userName + " - " + new Date().toLocaleDateString())
                this.redactionNote = "";
                this.saveAdvertisement(item);
            }
        },
        addContactQuestion(item) {
            if (this.contactQuestion.length > 0) {
                if (!item.advContactQuestions) item.advContactQuestions = [];
                //add username and date
                item.advContactQuestions.push({ msg: this.contactQuestion, status: "open" })
                this.contactQuestion = "";
                item.openQuestion = true;
                this.saveAdvertisement(item);
                let userName = item.contact
                let subject = `Adv ${item.advertiserUserId}:  Aanvullende vraag`
                let html = `<p>Beste,<br><br>
                Er is een aanvullende vraag gesteld over de advertentie van ${item.name}.<br>
                Deze vraag blijft open staan tot je hem als "opgelost" hebt gemarkeerd.<br>
                <br>
                Met vriendelijke groet,<br>
                <br>
                Commissie krant
                <br>
                <i>(Dit bericht is automatisch gegenereerd en kan niet worden beantwoord)</i>
                </p>`
                    
                this.$store.commit("sendInternalMail", {
                userName: userName, subject: subject, html: html
            });
            }
        },
        markAsFulfilled(item, index) {
            item.advContactQuestions[index].status = "Opgelost";
            item.openQuestion = false
            item.advContactQuestions.forEach(question => {
                if (question.status == "open") {
                    item.openQuestion = true;
                }
            });
            this.saveAdvertisement(item);
        },
        saveAdvertisement(item) {
            this.$store.commit("paperStore/saveAdvertisement", {
                advertisement: item,
            });
        },
    }
}
</script>