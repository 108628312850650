<template>
    <v-container>
        <v-radio-group v-model="paymentMethod">
            <v-radio label="Factuur" value="factuur"></v-radio>
            <v-radio label="Contant" value="contant"></v-radio>
            <v-radio label="leeg" :value="null"></v-radio>
            <v-radio label="gratis" value="gratis"></v-radio>
        </v-radio-group>
        <span>TODO: make :items dynamic to implement the computed NotCompletedAds property</span>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" class="ma-5" single-line
            hide-details></v-text-field>
        <v-data-table
            :headers="headers"
            :items="allSoldAdvertisers" class="elevation-1" disable-pagination hide-default-footer 
             :search="search" sort-by="advFormat">
           
        </v-data-table>
    </v-container>
</template>
<script>

export default {
    name: "AdvertiserRecordCheck",
   

    data() {
        return {
            search: "",
            headers: [
                { text: 'nr', value: 'advertiserUserId' , align: 'start'},
                { text: 'Bedrijfsnaam', value: 'name' },
                { text: 'verantwoordelijke', value: 'contact'},
                { text: 'adres', value: 'adres'},
                { text: 'postcode', value: 'postalCode'},
                { text: 'stad', value: 'city'},
                { text: 'email', value: 'email'},
            ],
            paymentMethod: "factuur"
        }
    },
    computed: {
        advertisers() {
            return this.$store.state.paperStore.advertisers;
        },
        activeAdvertisements() {
            return this.$store.state.paperStore.activeAdvertisements;
        },
        activeEdition() {
            let activeEditionId = this.$store.state.paperStore.activeEditionId;
            let editions = this.$store.state.paperStore.paperEditions;
            return editions.find(edition => edition.id == activeEditionId);
        },
        allSoldAdvertisers() {
            let adv = [];
            this.advertisers.forEach(advertiser => {
                let ad = this.activeAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
                if (ad) {
                    if(ad.advState === "verkocht" && ad.advPaymentMethod === this.paymentMethod){
                        adv.push(advertiser);
                    }
                    
                }
                
            });
            return adv
        },

        notPaidAdvertisers() {
            let notPaid = [];
            this.advertisers.forEach(advertiser => {
                let ad = this.activeAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
                if(ad.advPaymentMethod === "factuur" && ad.advState === "verkocht" && (ad.advPaid === false || ad.advPaid === null || ad.advPaid === undefined)){
                    ad.adres = advertiser.adres;
                    ad.postalCode = advertiser.postalCode;
                    ad.city = advertiser.city;
                    ad.email = advertiser.email;
                    notPaid.push(ad);
                }
            });
            return notPaid;
        },

        NotCompletedAds() {
            let notCompleted = [];
            let emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            this.allSoldAdvertisers.forEach(ad => {
                if(!ad.adres || ad.adres.length < 1){
                    notCompleted.push(ad);
                } else if(!ad.postalCode || ad.postalCode.length < 1){
                    notCompleted.push(ad);
                } else if(!ad.city || ad.city.length < 1){
                    notCompleted.push(ad);
                } else if(!ad.email ||  !emailRegex.test(ad.email)){
                    notCompleted.push(ad)
                }
            });
            return notCompleted;
        },
    },
    methods: {
       
    }
}
</script>