<template>
  <div>
    <div>
      <h1 class="subheading grey--text">Gebruikers</h1>

      <v-btn v-if="!buzzy" class="my-5" router to="/gebruikertoevoegen">
        <v-icon>mdi-account-plus-outline</v-icon>
      </v-btn>
    </div>
    <v-container v-if="!buzzy" class="my-5">
      <template>
        <v-card v-for="user in users" :key="user.id" class="mx-auto ma-4" max-width="600">
          <v-list-item two-line>
            <v-list-item-content v-if="user">
              <v-list-item-title>{{user.name}}</v-list-item-title>
              <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-row justify="center">
              <v-btn outlined rounded text class="ma-2">
                <router-link :to="{name: 'EditUser', params:{uid: user.id}}" class="black--text">
                  <span>Aanpassen</span>
                  <v-icon small right>mdi-account-edit-outline</v-icon>
                </router-link>
              </v-btn>
              <v-btn @click="deleteDialog = true, userToDelete = user.id" outlined rounded text class="ma-2">
                <span>Verwijderen</span>
                <v-icon small right>mdi-account-remove-outline</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-divider></v-divider>
      </template>
    </v-container>
    <v-dialog v-model="deleteDialog" v-if="!buzzy" max-width="450">
      <v-card>
        <v-card-title class="headline" style="word-break: break-word; overflow-wrap: break-word;">Weet je zeker dat je deze gebruiker wilt verwijderen?</v-card-title>
        <v-card-actions >
          <v-btn @click="deleteDialog = false">Annuleren</v-btn>
          <v-btn @click="removeUser()" color="red" dark>Verwijderen</v-btn>
        </v-card-actions>
        
      </v-card>	
    </v-dialog>
    <v-progress-circular v-if="buzzy"
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deleteDialog: false,
      buzzy: false,
      userToDelete: null
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  watch: {
    users() {
      if(this.users.length > 0) {
        this.buzzy = false;
      }
    }
  },
  methods: {
    removeUser() {
      this.buzzy = true;
      this.$store.dispatch("removeUser", this.userToDelete);
      this.userToDelete = null;
      this.deleteDialog = false;
      setTimeout(() => {
        this.$store.dispatch("loadUsers");
        this.buzzy = false;
      }, 2000);
    }
  },
  mounted() {
    this.$store.dispatch("loadUsers");
  }
};
</script>

<style>
.v-expansion-panel-header {
  width: 0%;
}
</style>