<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Liedjesfestival</span>
      </h1>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutEditModules">
      <v-flex name="flexIntroWinnersEnlistDocs">
        <v-flex class="ma-2" xs12 name="flexIntro">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Introductie tekst Liedjesfestival</b>
              </span>
            </v-system-bar>
            <v-textarea
              class="pa-2"
              v-model="liedjesIntro"
              disabled
              v-if="lockIntro"
            ></v-textarea>
            <v-textarea
              class="pa-2"
              v-model="newIntro"
              v-if="!lockIntro"
            ></v-textarea>
            <v-card-actions>
              <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
              <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
              <v-btn v-if="!lockIntro" @click="lockIntro = true"
                >Annuleren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex class="ma-2" xs12 name="flexWinners">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Laatste winnaars</b>
              </span>
            </v-system-bar>
            <v-textarea
              class="pa-2"
              v-model="contentLiedjes.text"
              disabled
              v-if="lockWinners"
            ></v-textarea>
            <v-textarea
              class="pa-2"
              v-model="newWinners.text"
              v-if="!lockWinners"
            ></v-textarea>
            <v-text-field
              class="pa-2"
              v-model="contentLiedjes.link"
              disabled
              v-if="lockWinners"
            ></v-text-field>
            <v-text-field
              class="pa-2"
              v-model="newWinners.link"
              v-if="!lockWinners"
            ></v-text-field>
            <v-card-actions>
              <v-btn @click="changeWinners()" v-if="lockWinners"
                >Aanpassen</v-btn
              >
              <v-btn v-if="!lockWinners" @click="updateWinners()"
                >Opslaan</v-btn
              >
              <v-btn v-if="!lockWinners" @click="lockIntro = true"
                >Annuleren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex class="ma-2" xs12 name="flexEnlist">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Inschrijvingen</b>
              </span>
            </v-system-bar>
            <v-switch
              label="(de)blokkeer de inschrijvingen op de website"
              class="pa-2"
              v-model="contentLiedjes.enlistmentOpen"
              @change="updateContent()"
              color="#cc212f"
            ></v-switch>
            <span class="ml-2">Welke editie kan men zich voor inschrijven</span>
            <v-layout row class="d-flex align-center ma-2">
              <v-row class="ma-2">
                <v-select
                  :items="years"
                  label="Editie"
                  v-model="contentLiedjes.enlistmentYear"
                  @change="updateContent()"
                ></v-select>
              </v-row>
              <v-row class="ma-2">
                <span
                  ><small
                    >Let op, de inschrijvingen van een bepaald jaar worden
                    gekoppeld aan bovenstaande editie. Verander dit dus niet
                    tijdens een inschrijvingsperiode!</small
                  ></span
                >
              </v-row>
            </v-layout>
            <v-system-bar class="ma-4">
              <span>
                <b>Overzicht inschrijvingen huidige editie</b>
              </span>
            </v-system-bar>
            <template class="ma-4">
              <v-data-table
                v-if="participants"
                :headers="headers"
                :items="participants"
                class="elevation-1"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.checked`]="{ item }">
                  <v-simple-checkbox
                    :ripple="false"
                    v-model="item.checked"
                    @click="confirmChecked(item.id, item.year)"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    class="mr-2"
                    @click="downloadForm(item.id, item.docType, item.actName)"
                  >
                    mdi-file-download-outline
                  </v-icon>
                </template>
              </v-data-table>
            </template>
          </v-card>
        </v-flex>
        <v-flex class="ma-2" xs12 name="flexDocs" v-if="contentLiedjes.docs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Documenten</b>
              </span>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="!editDocs">
                <v-icon color="#cc212f" @click="editDocs = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text v-if="!editDocs && contentLiedjes.docs.length == 0">
              <span> Er zijn nog geen documenten geupload. </span>
            </v-card-text>

            <v-form class="pa-2" v-if="editDocs">
              <h4>Nieuw documenten uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newDoc.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newDoc.description"
              >
              </v-textarea>
              <v-file-input
                label="te uploaden document: "
                v-model="newDoc.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-btn @click="(editDocs = false), (newDoc = {})"
                >Annuleren</v-btn
              >
              <v-btn v-if="newDoc.title && newDoc.doc" @click="saveNewDoc()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list
              v-if="contentLiedjes.docs.length > 0 && !editDocs"
              three-line
            >
              <v-list-item v-for="(doc, i) in contentLiedjes.docs" :key="i">
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ doc.creationDate }} - {{ doc.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  @click="(deleteDocDialog = true), (deleteDoc = doc.id)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteDocDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Document verwijderen?</v-card-title>
        <v-card-text>Deze actie is onomkeerbaar.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDocDialog = false), (deleteDoc = null)"
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="deleteDocF(deleteDoc)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Axios from "axios";
import firebase from "firebase";
export default {
  data() {
    return {
      lockIntro: true,
      lockWinners: true,
      newIntro: null,
      newWinners: null,
      newDoc: {},
      deleteDoc: null,
      editDocs: false,
      deleteDocDialog: false,
      buzzy: false,
      years: [
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
      ],
      headers: [
        { text: "goedgekeurd", align: "start", value: "checked" },
        {
          text: "Naam van act",
          value: "actName",
        },
        {
          text: "Lied",
          value: "songName",
        },
        {
          text: "Aantal personen",
          value: "numberOfParticipants",
        },
        { text: "Contactpersoon", value: "contactName" },
        { text: "Tel nr", value: "contactPhone" },
        { text: "email", value: "contactEmail" },
        { text: "Download", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    liedjesIntro() {
      return this.$store.state.intros.liedjes;
    },
    contentLiedjes() {
      return this.$store.state.contentLiedjes;
    },
    participants() {
      let store = null;
      let participants = null;
      if (this.$store.state.liedjesParticipants) {
        store = this.$store.state.liedjesParticipants;
        let keys = Object.keys(store);
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === this.contentLiedjes.enlistmentYear) {
            participants = [];
            for (let j = 0; j < store[keys[i]].length; j++) {
              participants.push(store[keys[i]][j]);
            }
          }
        }
      }

      return participants;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
  },
  methods: {
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.liedjesIntro;
    },
    updateIntro() {
      let intro = {
        name: "liedjes",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
    changeWinners() {
      this.lockWinners = false;
      this.newWinners = this.contentLiedjes;
    },
    updateWinners() {
      let link = this.newWinners.link.split("=").pop();
      this.contentLiedjes.text = this.newWinners.text;
      this.contentLiedjes.link = link;
      this.$store.dispatch("updateContentLiedjes", {
        content: this.contentLiedjes,
      });
      setTimeout(() => {
        this.lockWinners = true;
      }, 2000);
    },
    updateContent() {
      this.$store.dispatch("updateContentLiedjes", {
        content: this.contentLiedjes,
      });
    },
    confirmChecked(id, year) {
      let storePart = this.$store.state.liedjesParticipants;
      let keys = Object.keys(storePart);
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === year) {
          for (let j = 0; j < storePart[keys[i]].length; j++) {
            if (storePart[keys[i]][j] === id) {
              storePart[keys[i]][j].checked != storePart[keys[i]][j].checked;
            }
          }
        }
      }
      this.$store.dispatch("updateParticipants", { participants: storePart });
    },
    downloadForm(id, file_type, name) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = name + " - Verklaring van orginaliteit";
      let file = firebase.storage().ref(`participantDocs/liedjes/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
    saveNewDoc() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        let doc = {
          id: id,
          title: this.newDoc.title,
          description: this.newDoc.description,
          doc_id: id,
          creator: creator,
          creationDate: creationDate,
          type: this.newDoc.doc.name.split(".").pop(),
        };

        let storageRef = firebase.storage().ref();
        storageRef.child(`publicDocs/liedjes/${id}`).put(this.newDoc.doc);
        this.contentLiedjes.docs.push(doc);
        this.$store.dispatch("updateContentLiedjes", {
          content: this.contentLiedjes,
        });
        this.newDoc = {};
        this.editDocs = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    deleteDocF(id) {
      this.buzzy = true;
      for (let i = 0; i < this.contentLiedjes.docs.length; i++) {
        if (this.contentLiedjes.docs[i].id === id) {
          if (this.contentLiedjes.docs[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef.child(`publicDocs/liedjes/${id}`).delete();
          }
          this.contentLiedjes.docs.splice(i, 1);
          this.$store.dispatch("updateContentLiedjes", {
            content: this.contentLiedjes,
          });
        }
      }
      this.deleteDoc = null;
      setTimeout(() => {
        this.deleteDocDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;
      let file = firebase.storage().ref(`publicDocs/liedjes/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },
  created() {
    this.$store.dispatch("loadContentLiedjes");
    this.$store.dispatch("loadLiedjesParticipants");
  },
};
</script>

<style>
</style>