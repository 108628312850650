<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Nieuwsberichten</span>
      </h1>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <v-icon
          size="60"
          color="#ebb63b"
          @click="(newEntryDialog = true), (newItem = {})"
          >mdi-plus-circle</v-icon
        >
      </div>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutNewsModules">
      <v-flex xs12 md11 name="flexOverviewNews">
        <v-flex class="ma-2" xs12>
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Overzicht nieuwsberichten</b>
              </span>
            </v-system-bar>
            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                v-model="panel"
                v-for="(item, i) in newsItems"
                :key="i"
              >
                <v-expansion-panel-header class="ahcGreen">
                  <b>{{ item.title }}</b>
                  <v-spacer></v-spacer>
                  <small>{{ item.creationDate }} - {{ item.creator }}</small>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img :src="images[item.id]" />
                    </v-flex>

                    <v-chip
                      v-for="(tag, j) in item.tags"
                      :key="j"
                      class="ma-2"
                      color="#ebb63b"
                    >
                      <span class="white--text">{{ tag }}</span>
                    </v-chip>

                    <v-card-text>
                      <p class="ahcGreen" tyle="white-space: pre-wrap;">
                        {{ item.text }}
                      </p>
                    </v-card-text>
                    <v-layout row class="d-flex align-center">
                      <v-flex xs2>
                        <v-icon
                          class="ma-3"
                          @click="
                            (deleteItemId = item.id),
                              (deleteNewsItemDialog = true)
                          "
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-flex>
                      <v-flex xs2>
                        <v-icon
                          class="ma-3"
                          @click="
                            (editNewsItemDialog = true), fillEditItem(item)
                          "
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                      <v-flex xs8 v-if="item.modified">
                        <span>
                          <small
                            >Aangepast: {{ item.modified.date }} -
                            {{ item.modified.by }}</small
                          >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-dialog
      transition="dialog-top-transition"
      max-width="1000"
      v-model="editNewsItemDialog"
    >
      <v-card v-if="!buzzy">
        <v-toolbar color="#ebb63b" class="text--white">
          <span>Nieuwsbericht aanpassen</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              (editItem = {}), (editNewsItemDialog = false), (newFoto = null)
            "
            >mdi-close-outline</v-icon
          >
        </v-toolbar>
        <v-form class="ma-3">
          <v-text-field label="Titel" v-model="editItem.title"></v-text-field>
          <v-textarea v-model="editItem.text" label="Tekst"></v-textarea>
          <v-select
            :items="tags"
            multiple
            v-model="editItem.tags"
            color="#cc212f"
            light
          >
          </v-select>
          <span v-if="editItem.foto">Foto:</span>
          <v-flex xs12 v-if="editItem.foto">
            <img :src="images[editItem.id]" />
          </v-flex>
          <v-btn
            color="green darken-1"
            text
            @click="deleteFoto(editItem.id), (editItem.foto = false)"
            >foto verwijderen</v-btn
          >
          <v-switch
            v-model="addNewImage"
            label="Nieuwe foto toevoegen?"
          ></v-switch>
          <v-file-input
            :disabled="!addNewImage"
            accept="image/png, image/jpeg, image/bmp"
            v-model="newImage"
            prepend-icon="mdi-camera"
          ></v-file-input>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(editNewsItemDialog = false), (editItem = {})"
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="editNewsItem()"
            >Aanpassen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Aanpassen nieuwsbericht</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="900"
      v-model="newEntryDialog"
    >
      <v-card>
        <v-toolbar color="#ebb63b" class="text--white">
          <span>Nieuw nieuwsbericht</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              (newItem = {}),
                (newEntryDialog = false),
                (newEntryStepper = 1),
                (newFoto = null)
            "
            >mdi-close-outline</v-icon
          >
        </v-toolbar>
        <v-stepper v-model="newEntryStepper" vertical v-if="newItem">
          <!-- Title -->
          <v-stepper-step
            color="#ebb63b"
            :complete="newEntryStepper > 1"
            step="1"
            >Wat is de titel van het nieuwsitem?</v-stepper-step
          >

          <v-stepper-content step="1">
            <v-text-field label="Titel" v-model="newItem.title"></v-text-field>
            <v-btn
              v-if="newItem.title"
              color="#ebb63b"
              @click="newEntryStepper = 2"
              >Volgende</v-btn
            >
          </v-stepper-content>

          <!-- Text -->
          <v-stepper-step
            color="#ebb63b"
            :complete="newEntryStepper > 2"
            step="2"
            >Welke tekst krijgt het nieuwsitem?</v-stepper-step
          >
          <v-stepper-content step="2">
            <v-textarea v-model="newItem.text" label="Tekst"></v-textarea>
            <v-btn
              v-if="newItem.text"
              color="#ebb63b"
              @click="newEntryStepper = 3"
              >Volgende</v-btn
            >
            <v-btn text @click="newEntryStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- add tags -->
          <v-stepper-step
            :complete="newEntryStepper > 3"
            step="3"
            color="#ebb63b"
          >
            Welke tags wilt u toevoegen?
            <small>Meerdere selecties mogelijk</small>
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-select
              :items="tags"
              multiple
              v-model="newItem.tags"
              color="#cc212f"
              light
            >
            </v-select>
            <v-btn color="#ebb63b" @click="newEntryStepper = 4">Volgende</v-btn>
            <v-btn text @click="newEntryStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- Add foto? -->
          <v-stepper-step
            color="#ebb63b"
            :complete="newEntryStepper > 4"
            step="4"
            >Wilt u een afbeelding toevoegen?</v-stepper-step
          >
          <v-stepper-content step="4">
            <v-switch v-model="newItem.foto" column justify="center"></v-switch>
            <v-file-input
              v-if="newItem.foto"
              accept="image/png, image/jpeg, image/bmp"
              v-model="newImage"
              prepend-icon="mdi-camera"
              label="Afbeelding"
            ></v-file-input>

            <v-btn
              v-if="!newItem.foto || newImage"
              color="#ebb63b"
              @click="newEntryStepper = 5"
              >Volgende</v-btn
            >
            <v-btn text @click="newEntryStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- Add link -->

          <v-stepper-step
            color="#ebb63b"
            :complete="newEntryStepper > 5"
            step="4"
            >Wilt u een link toevoegen?</v-stepper-step
          >
          <v-stepper-content step="5">
            <v-switch v-model="newItem.link" column justify="center"></v-switch>
            <v-text-field
              v-if="newItem.link"
              label="Link"
              v-model="newItem.linkURL"
            ></v-text-field>
            <v-btn
              v-if="!newItem.link || newItem.linkURL"
              color="#ebb63b"
              @click="newEntryStepper = 6"
              >Volgende</v-btn
            >
            <v-btn text @click="newEntryStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- postponement of publication -->
          <v-stepper-step
            color="#ebb63b"
            :complete="newEntryStepper > 6"
            step="4"
            >Wilt u de publicatie uitstellen?</v-stepper-step
          >
          <v-stepper-content step="6">
            <v-radio-group v-model="newItem.postpone" column justify="center">
              <v-radio label="Nee" value="no"></v-radio>
              <v-radio label="Ja" value="yes" disabled></v-radio>
            </v-radio-group>
            <v-btn
              v-if="newItem.postpone == 'yes'"
              color="#ebb63b"
              @click="newEntryStepper = 7"
              >Volgende</v-btn
            >
            <v-btn
              v-if="newItem.postpone == 'no'"
              color="#ebb63b"
              @click="addNewsItem()"
              >Item aanmaken</v-btn
            >
            <v-btn text @click="newEntryStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- date/time -->
          <v-stepper-step
            :complete="newEntryStepper > 7"
            step="5"
            color="#ebb63b"
            >Wanneer wilt u het artikel publiceren?</v-stepper-step
          >

          <v-stepper-content step="7">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="newItem.postponementDate"
                  label="Publicatie datum?"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="newItem.postponementDate"
                :max="maxYear().toISOString().substr(0, 10)"
                :min="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
            <v-btn
              v-if="newItem.postponementDate"
              color="#ebb63b"
              @click="addNewsItem()"
              >Item aanmaken</v-btn
            >
            <v-btn text @click="newEntryStepper -= 1">Terug</v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteNewsItemDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass"
          >Nieuwsbericht verwijderen?</v-card-title
        >
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteItemDialog = false), (deleteItemId = null)"
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            @click="deleteNewsItem(deleteItemId)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen nieuwsbericht</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      newEntryDialog: false,
      deleteItemId: null,
      deleteNewsItemDialog: false,
      newEntryStepper: 1,
      newItem: {},
      newImage: null,
      tags: [
        "ubpf",
        "liedjesfestival",
        "carnavalskrant",
        "kindercarnaval",
        "pagadder",
        "zomercarnaval"
      ],
      panel: 0,
      buzzy: false,
      editItem: {},
      editNewsItemDialog: false,
      addNewImage: false,
    };
  },
  computed: {
    userName() {
      return this.$store.state.currentUser.name;
    },
    newsItems() {
      return this.$store.state.newsItems;
    },
    showImage() {
      return this.$store.state.testImage;
    },
    images() {
      return this.$store.state.newsImages;
    },
  },
  methods: {
    addNewsItem() {
      try {
        let id = Date.now();
        this.newItem.id = id;
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        this.newItem.creator = this.userName;
        this.newItem.creationDate = creationDate;
        if (this.newItem.foto) {
          this.uploadNewsImage(id);
        }
        this.$store.dispatch("addNewsItem", this.newItem);
        this.newImage = null;
        this.newEntryDialog = false;
        this.newEntryStepper = 1;
        setTimeout(() => {
          this.$store.dispatch("getNewsImages");
        }, 3000);
      } catch (error) {
        console.log("Toevoegen nieuwsitem is mislukt " + error.message);
      }
    },
    uploadNewsImage(id) {
      let file = this.newImage;
      let file_type = file.type;
      if (file_type && file_type.substr(0, 5) == "image") {
        let img = document.createElement("img");
        let reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let MAX_WIDTH = 400;
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              let storageRef = firebase.storage().ref(`images/news/${id}`);
              storageRef.put(blob);
            });
          };
        };
        reader.readAsDataURL(file);
      }
      this.newImage = null;
    },
    deleteNewsItem(id) {
      this.buzzy = true;
      this.$store.dispatch("deleteNewsItem", id);
      this.editItemId = null;
      setTimeout(() => {
        this.$store.dispatch("getNewsItems");
        this.$store.dispatch("getNewsImages");
        this.deleteNewsItemDialog = false;
        this.buzzy = false;
      }, 5000);
    },
    fillEditItem(item) {
      this.editItem.id = item.id;
      this.editItem.title = item.title;
      this.editItem.text = item.text;
      this.editItem.modified = item.modified;
      this.editItem.creator = item.creator;
      this.editItem.foto = item.foto;
      this.editItem.creationDate = item.creationDate;
    },
    deleteFoto(id) {
      let deleteRef = firebase.storage().ref(`images/news/${id}`);
      deleteRef.delete();
    },

    editNewsItem() {
      try {
        this.buzzy = true;
        this.editItem.modified = {};
        let modifyDate = new Date();
        let dd = String(modifyDate.getDate()).padStart(2, "0");
        let mm = String(modifyDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = modifyDate.getFullYear();
        modifyDate = dd + "/" + mm + "/" + yyyy;
        this.editItem.modified.by = this.userName;
        this.editItem.modified.date = modifyDate;
        if (this.newImage) {
          this.editItem.foto = true;
          this.uploadNewsImage(this.editItem.id);
        }
        this.$store.dispatch("addNewsItem", this.editItem);
        setTimeout(() => {
          this.$store.dispatch("getNewsImages");
          this.editNewsItemDialog = false;
          this.buzzy = false;
        }, 5000);
      } catch (error) {
        console.log("Aanpassen nieuwsitem is mislukt " + error.message);
      }
    },
    maxYear() {
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = d.getDate();
      let c = new Date(year + 2, month, day);
      return c;
    },
  },
  created() {
    this.$store.dispatch("getNewsImages");
    this.$store.dispatch("getNewsItems");
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
.v-expansion-panel-header {
  width: 100%;
}
</style>