
import firebase from '../firebase/init'

export default {
  namespaced: true,
  state: {
    commissions: [],
    commissionContent: {}
  },
  getters: {
    getUserCommissions: state => (userId) => {
      let commissions = []
      state.commissions.forEach(commission => {
        if (commission.preces === userId || commission.members.includes(userId)) {
          commissions.push(commission)
        }
      })
      commissions.sort((a, b) => a.index - b.index) 
      return commissions
    },
    getCommissionUsers: ( state, getters, rootState) => (commissionId) => {
      let users = rootState.users ?? []
      let commissionUsers = []
      users.forEach(user => {
        if (user.permissions?.commissions?.includes(commissionId) && !user.permissions?.commissions?.includes(`${commissionId}-vz`)) {
          commissionUsers.push(user)
        }
      })
      return commissionUsers
    },
    getCommissionPreces: ( state, getters, rootState) => (commissionId) => {
      let users = rootState.users ?? []
      let commissionPreces = []
      users.forEach(user => {
        if (user.permissions?.commissions?.includes(`${commissionId}-vz`)) {
          commissionPreces.push(user)
        }
      })
      return commissionPreces
    }
  },

  mutations: {
    setCommissions(state, payload) {
      state.commissions = payload.commissions
    },
    setCommissionContent(state, payload) {
      state.commissionContent = payload.commissionContent
    },
    clearCommissionContent(state) {
      state.commissionContent = {}
    },



  },
  actions: {
    saveDraaiboekCategory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let saveDraaiboekCategory = firebase.functions().httpsCallable("saveDraaiboekCategory");
          saveDraaiboekCategory(payload)
            .then(result => {
              if (result.data.success) {
                commit('setCommissionContent', { commissionContent: result.data.commissionData });
                resolve({success: true});
              } else {
                console.log("Error in saveDraaiboekCategory in store: " + result.data.message);
                reject({success: false});
              }
            })
            .catch(error => {
              console.log("Error in saveDraaiboekCategory in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in saveDraaiboekCategory: ' + error.message);
          reject(error);
        }
      });
    },
    addTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let addTask = firebase.functions().httpsCallable("addTask");
          addTask(payload)
            .then(result => {
              if (result.data.success) {
                commit('setCommissionContent', { commissionContent: result.data.commissionData });
                resolve({success: true});
              } else {
                console.log("Error in addTask in store: " + result.data.message);
                reject({success: false});
              }
            })
            .catch(error => {
              console.log("Error in addTask in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in addTask: ' + error.message);
          reject(error);
        }
      });
    },
    addTaskFromDraaiboek({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let addTaskFromDraaiboek = firebase.functions().httpsCallable("addTaskFromDraaiboek");
          addTaskFromDraaiboek(payload)
            .then(result => {
              if (result.data.success) {
                commit('setCommissionContent', { commissionContent: result.data.commissionData });
                resolve({success: true});
              } else {
                console.log("Error in addTaskFromDraaiboek in store: " + result.data.message);
                reject({success: false});
              }
            })
            .catch(error => {
              console.log("Error in addTaskFromDraaiboek in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in addTaskFromDraaiboek: ' + error.message);
          reject(error);
        }
      });
    },
    deleteSingleTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let deleteSingleTask = firebase.functions().httpsCallable("deleteSingleTask");
          deleteSingleTask(payload)
            .then(result => {
              if (result.data.success) {
                commit('setCommissionContent', { commissionContent: result.data.commissionData });
                resolve({success: true});
              } else {
                console.log("Error in deleteSingleTask in store: " + result.data.message);
                reject({success: false});
              }
            })
            .catch(error => {
              console.log("Error in deleteSingleTask in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in deleteSingleTask: ' + error.message);
          reject(error);
        }
      });
    },
    deleteDraaiboekTasks({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let deleteDraaiboekTasks = firebase.functions().httpsCallable("deleteDraaiboekTasks");
          deleteDraaiboekTasks(payload)
            .then(result => {
              if (result.data.success) {
                commit('setCommissionContent', { commissionContent: result.data.commissionData });
                resolve({success: true});
              } else {
                console.log("Error in deleteDraaiboekTasks in store: " + result.data.message);
                reject({success: false});
              }
            })
            .catch(error => {
              console.log("Error in deleteDraaiboekTasks in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in deleteDraaiboekTasks: ' + error.message);
          reject(error);
        }
      });
    },
    finishTask({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let finishTask = firebase.functions().httpsCallable("finishTask");
          finishTask(payload)
            .then(result => {
              if (result.data.success) {
                commit('setCommissionContent', { commissionContent: result.data.commissionData });
                resolve({success: true});
              } else {
                console.log("Error in finishTask in store: " + result.data.message);
                reject({success: false});
              }
            })
            .catch(error => {
              console.log("Error in finishTask in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in finishTask: ' + error.message);
          reject(error);
        }
      })
    },
    addCommission({  dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let addCommission = firebase.functions().httpsCallable("addCommission");
          addCommission(payload)
            .then(result => {
              if (result.data.success) {
                dispatch('loadCommissions');
                resolve(result.data);
              } else {
                reject(result.data);
              }
            })
            .catch(error => {
              console.log("Error in addCommission in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in addCommission: ' + error.message);
          reject(error);
        }
      });
    },
    loadCommissions({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          let loadCommission = firebase.functions().httpsCallable("loadCommissions");
          loadCommission()
            .then(result => {
              if (result.data.success) {
                commit('setCommissions', { commissions: result.data.commissions });
                resolve(result.data);
              } else {
                reject(result.data);
              }
            })
            .catch(error => {
              console.log("Error in loadCommission in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in loadCommission: ' + error.message);
          reject(error);
        }
      });
    },
    loadCommissionContent({ commit }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let loadCommissionContent = firebase.functions().httpsCallable("loadCommissionContent");
          loadCommissionContent(payload)
            .then(result => {
              if (result.data.success) {
                commit('setCommissionContent', { commissionContent: result.data.commission });
                resolve(result.data);
              } else {
                reject(result.data);
              }
            })
            .catch(error => {
              console.log("Error in loadCommissionContent in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in loadCommissionContent: ' + error.message);
          reject(error);
        }
      });
    },
    clearCommissionContent({ commit }) {
      commit('clearCommissionContent');
    },
    saveCommissionScript({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let saveCommissionScript = firebase.functions().httpsCallable("saveCommissionScript");
          saveCommissionScript(payload)
            .then(result => {
              if (result.data.success) {
                dispatch('loadCommissionContent', { id: payload.commissionId });
                resolve(result.data);
              } else {
                reject(result.data);
              }
            })
            .catch(error => {
              console.log("Error in saveCommissionScript in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in saveCommissionScript: ' + error.message);
          reject(error);
        }
      });
    },
    saveCommissionDoc({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let saveCommissionDoc = firebase.functions().httpsCallable("saveCommissionDoc");
          saveCommissionDoc(payload)
            .then(result => {
              if (result.data.success) {
                dispatch('loadCommissionContent', { id: payload.commissionId });
                resolve(result.data);
              } else {
                reject(result.data);
              }
            })
            .catch(error => {
              console.log("Error in saveCommissionDoc in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in saveCommissionDoc: ' + error.message);
          reject(error);
        }
      });
    },
    deleteCommissionScript({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let deleteCommissionScript = firebase.functions().httpsCallable("deleteCommissionScript");
          deleteCommissionScript(payload)
            .then(result => {
              if (result.data.success) {
                dispatch('loadCommissionContent', { id: payload.commissionId });
                resolve(result.data);
              } else {
                reject(result.data);
              }
            })
            .catch(error => {
              console.log("Error in deleteCommissionScript in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in deleteCommissionScript: ' + error.message);
          reject(error);
        }
      });
    },
    deleteCommissionDoc({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          let deleteCommissionDoc = firebase.functions().httpsCallable("deleteCommissionDoc");
          deleteCommissionDoc(payload)
            .then(result => {
              if (result.data.success) {
                dispatch('loadCommissionContent', { id: payload.commissionId });
                resolve(result.data);
              } else {
                reject(result.data);
              }
            })
            .catch(error => {
              console.log("Error in deleteCommissionDoc in store: " + error.message);
              reject(error);
            });
        } catch (error) {
          console.log('Error in deleteCommissionDoc: ' + error.message);
          reject(error);
        }
      });
    },
  },
}