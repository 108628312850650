<template>
  <v-container>
    <v-footer class="d-flex justify-space-between" padless color="#cc212f">
      <span class="white--text px-3">
        &#169;{{ new Date().getFullYear() }} —
        <strong>JSA-it</strong>
      </span>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
</style>