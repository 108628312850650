<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Jaarprogramma</span>
      </h1>
      <v-spacer></v-spacer>
      <div
        class="d-flex"
        v-if="
          currentUser && currentUser.role == 'Admin' && editCalendar == false
        "
      >
        <v-icon @click="(editCalendar = true), (change = true)" color="#ebb63b"
          >mdi-pencil-outline</v-icon
        >
      </div>
      <div
        class="d-flex"
        v-if="
          currentUser && currentUser.role == 'Admin' && editCalendar == true
        "
      >
        <v-icon
          @click="(editCalendar = false), saveCalendar(), (change = false)"
          color="#ebb63b"
          >mdi-content-save-edit</v-icon
        >
      </div>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutCalendarModule">
      <v-flex xs12 name="flexCalendar">
        <v-flex class="ma-2">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b"> </v-system-bar>
            <v-card-text>
              <v-form
                align="center"
                class="ma-5 d-flex flex-row"
                v-if="editCalendar"
              >
                <v-date-picker v-model="newDate.date" no-title scrollable>
                </v-date-picker>
                <v-text-field
                  v-model="newDate.name"
                  label="Naam"
                  class="grey--text"
                ></v-text-field>
                <v-text-field
                  v-model="newDate.comment"
                  label="Opmerking"
                  class="grey--text"
                >
                </v-text-field>

                <div>
                  <v-icon color="#ebb63b" @click="addItem"
                    >mdi-plus-circle</v-icon
                  >
                </div>
              </v-form>
              <template class="ma-4">
                <v-data-table
                  :headers="headers"
                  :items="calendar"
                  class="elevation-0"
                  sortBy="date"
                  hide-default-footer
                  loading-text="Het jaarprogramma wordt ingeladen"
                  disable-pagination
                >
                  <template
                    v-slot:[`item.actions`]="{ item }"
                    v-if="
                      currentUser &&
                      currentUser.role == 'Admin' &&
                      editCalendar == true
                    "
                  >
                    <v-icon
                      class="ml-5"
                      color="#cc212f"
                      @click="deleteItem(item)"
                    >
                      mdi-minus-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-card
      class="ma-5"
      elevation="0"
      v-if="calendar.length == 0 && loadingTimer"
    >
      <v-row class="d-flex justify-space-around mb-6">
        <v-progress-circular
          class="ma-5 ahcRed"
          :size="70"
          :width="7"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6">
        <h3>Inladen jaarprogramma...</h3>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      editCalendar: false,
      newDate: {},
      datePicker: false,
      headers: [
        {
          text: "Datum",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Activiteit",
          sortable: false,
          value: "name",
        },
        {
          text: "Opmerkingen",
          sortable: false,
          value: "comment",
        },
        { sortable: false, value: "actions" },
      ],
      change: false,
      loadingTimer: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    calendar() {
      return this.$store.state.calendar;
    },
  },
  methods: {
    addItem() {
      this.$store.state.calendar.push(this.newDate);
      this.newDate = {};
    },
    deleteItem(item) {
      let index = this.$store.state.calendar.indexOf(item);
      this.$store.state.calendar.splice(index, 1);
    },
    saveCalendar() {
      this.$store.dispatch("saveCalendar");
    },
  },
  created() {
    this.$store.dispatch("loadCalendar");
    setTimeout(() => {
      this.loadingTimer = false;
    }, 5000);
  },
  beforeRouteLeave(to, from, next) {
    if (this.change) {
      const answer = window.confirm(
        "Weet je zeker dat je de pagina wilt verlaten zonder de wijzigingen op te slaan?"
      );
      if (answer) {
        this.change = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style>
</style>