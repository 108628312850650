<template>
    <v-container>
      <v-layout row wrap xs12 class="ma-2 justify-space-between">
        <h1>
          <span class="ahcRed">Jeugdraad</span>
        </h1>
      </v-layout>
      <v-layout row wrap class="ma-3" name="layoutEditModules">
        <v-flex xs12 md5 name="flexIntro">
          <v-flex class="ma-2" xs12 name="flexIntro">
            <v-card name="introWebsite">
              <v-system-bar class="pa-4" color="#ebb63b">
                <span>
                  <b>Introductie tekst Jeugdraad</b>
                </span>
              </v-system-bar>
              <v-textarea
                class="pa-2"
                v-model="jeugdraadIntro"
                disabled
                v-if="lockIntro"
              ></v-textarea>
              <v-textarea
                class="pa-2"
                v-model="newIntro"
                v-if="!lockIntro"
              ></v-textarea>
              <v-card-actions>
                <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
                <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
                <v-btn v-if="!lockIntro" @click="lockIntro = true"
                  >Annuleren</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex class="ma-2" xs12 name="flexCarousel">
            <v-card>
            <v-system-bar flat color="#ebb63b">
              <span>
                <b>Jeugdraad carrousel</b>
              </span></v-system-bar
            >
            <v-tabs v-model="tab" align-with-title color="#cc212f">
              <v-tabs-slider color="#cc212f"></v-tabs-slider>
              <v-tab v-for="(pic, i) in contentJeugdraad.pics" :key="i">
                {{ pic.name }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(pic, i) in contentJeugdraad.pics" :key="i">
                <v-card flat>
                  <v-row class="d-flex justify-space-around ma-3">
                    <img
                      v-if="pic.image"
                      :src="pic.foto"
                      width="80%"
                      contain
                    />
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
              <v-btn @click="changeCarrouselDialog = true"> aanpassen </v-btn>
            </v-card-actions>
          </v-card>
          </v-flex>
        </v-flex>
      </v-layout>
      <v-dialog
      transition="dialog-top-transition"
      max-width="900"
      v-model="changeCarrouselDialog"
    >
      <v-card v-if="!buzzy">
        <v-system-bar class="pa-4" color="#ebb63b">
          <span>
            <b>Programma carrousel aanpassen</b>
          </span>
        </v-system-bar>
        <v-alert
          outlined
          color="#ebb63b"
          class="ma-5"
          v-for="(pic, i) in contentJeugdraad.pics"
          :key="i"
        >
          <div class="ma-3">
            <v-text-field
              v-model="pic.name"
              placeholder="(zondag, kindercarnaval, etc)"
              label="Naam van foto (Jeugdprins, pliessie, etc)"
            >
            </v-text-field>
            <div v-if="pic.image">
              <img
                :src="pic.foto"
                contain
                width="80%"
                class="img-circle carrouselImage"
              />
              <v-btn text @click="deleteImage(pic.id), (pic.image = false)"
                >foto verwijderen</v-btn
              >
            </div>
            <div v-if="!pic.image">
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                v-model="newPic"
                prepend-icon="mdi-camera"
                @change="addImage(pic.id), (pic.image = true)"
              ></v-file-input>
            </div>
            <v-btn text @click="deletePic(pic.id)"
              >foto verwijderen<v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-alert>
        <v-icon class="ma-5" @click="addPic()" color="#cc212f"
          >mdi-plus</v-icon
        >
        <v-card-actions>
          <v-btn text @click="changeCarrouselDialog = false">Annuleren</v-btn>
          <v-btn text @click="updateCarrousel(), (buzzy = true)">Opslaan</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Carrousel aan het updaten....</h3>
        </v-row>
      </v-card>
    </v-dialog>
      
        
    </v-container>
  </template>
  
  <script>
    import firebase from "firebase/app";
  export default {
    data() {
      return {
        lockIntro: true,
        newIntro: null,
        buzzy: false,
        changeCarrouselDialog: false,
        newPic: null,
        tab: null,
      };
    },
    computed: {
      userName() {
        return this.$store.state.currentUser.name;
      },
      jeugdraadIntro() {
        return this.$store.state.intros.jeugdraad;
      },
      contentJeugdraad() {
        return this.$store.state.contentJeugdraad;
      },
    },
    methods: {
      changeIntro() {
        this.lockIntro = false;
        this.newIntro = this.jeugdraadIntro;
      },
      updateIntro() {
        let intro = {
          name: "jeugdraad",
          text: this.newIntro,
        };
        this.$store.dispatch("updateIntros", intro);
        setTimeout(() => {
          this.lockIntro = true;
        }, 2000);
      },
      updateCarrousel() {
      this.$store.dispatch("updateContentJeugdraad");
      setTimeout(() => {
        this.changeCarrouselDialog = false;
        this.buzzy = false;
      }, 4000);
    },
    deleteImage(id) {
      let deleteRef = firebase.storage().ref(`images/jeugdraad/carrousel/${id}`);
      deleteRef.delete();
    },
    addImage(id) {
      let file = this.newPic;
      let file_type = file.type;
      if (file_type && file_type.substr(0, 5) == "image") {
        let img = document.createElement("img");
        let reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let MAX_WIDTH = 1500;
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              let storageRef = firebase
                .storage()
                .ref(`images/jeugdraad/carrousel/${id}`);
              storageRef.put(blob);
            });
          };
        };
        reader.readAsDataURL(file);
      }
      this.newPic = null;
    },
    deletePic(id) {
      for (let i = 0; i < this.contentJeugdraad.pics.length; i++) {
        if (this.contentJeugdraad.pics[i].id === id) {
          this.contentJeugdraad.pics.splice(i, 1);
        }
      }
      this.deleteImage(id);
    },
    addPic() {
      let pic = {
        id: Date.now(),
        image: false,
      };
      if(!this.contentJeugdraad.pics){
        this.contentJeugdraad.pics = [];
      }

      this.contentJeugdraad.pics.push(pic);
    },
    

    },
    created() {
      this.$store.dispatch("loadContentJeugdraad");
    },
  };
  </script>
  
  <style>
  </style>