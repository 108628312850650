<template>
    <v-container>
        <!-- <v-btn class="ma-3" color="blue darken-1" text @click="sendFac()">Verstuur facturatie</v-btn> -->
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Zoeken" class="ma-5" single-line
            hide-details></v-text-field>
        <v-data-table :headers="headers" :items="allSoldAdvertisers" class="elevation-1" disable-pagination
            hide-default-footer :search="search" sort-by="advFormat">
            <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem = item, openDialog = true">
                            mdi-check
                        </v-icon>
                    </template>
                    <span>Verwerk betaling</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.advertiserUserId`]="{ item }">
            <span v-if="item.advPaid === false || item.advPaid === null || item.advPaid === undefined">{{ item.advertiserUserId }}</span>
            <strike v-else>{{ item.advertiserUserId }}</strike>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span v-if="item.advPaid === false || item.advPaid === null || item.advPaid === undefined">{{ item.name }}</span>
            <strike v-else>{{ item.name }}</strike>
          </template>
          <template v-slot:[`item.contact`]="{ item }">
            <span v-if="item.advPaid === false || item.advPaid === null || item.advPaid === undefined">{{ item.contact }}</span>
            <strike v-else>{{ item.contact }}</strike>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <v-icon :color="getStatusColor(item)" dark>
                  mdi-circle
                </v-icon>
          </template>
        </v-data-table>
        <v-dialog v-model="openDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Betaald?</span>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="paid(false)">Nee</v-btn>
                    <v-btn color="blue darken-1" text @click="paid(true)">Ja</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>

export default {
    name: "AdvertiserRecordCheck",


    data() {
        return {
            search: "",
            headers: [
                { text: 'nr', value: 'advertiserUserId', align: 'start' },
                { text: 'Bedrijfsnaam', value: 'name' },
                { text: 'Verantwoordelijke', value: 'contact' },
                { text: 'Afgesloten', value: 'paid' },
                { text: 'Bet. verwerken', value: 'actions' },
            ],
            openDialog: false,
            editItem: {},
        }
    },
    computed: {
        advertisers() {
            return this.$store.state.paperStore.advertisers;
        },
        activeAdvertisements() {
            return this.$store.state.paperStore.activeAdvertisements;
        },
        activeEdition() {
            let activeEditionId = this.$store.state.paperStore.activeEditionId;
            let editions = this.$store.state.paperStore.paperEditions;
            return editions.find(edition => edition.id == activeEditionId);
        },
        allSoldAdvertisers() {
            let adv = [];
            this.advertisers.forEach(advertiser => {
                let ad = this.activeAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
                if (ad) {
                    if (ad.advState === "verkocht" && ad.advPaymentMethod === 'factuur') {
                        adv.push(ad);
                    }

                }

            });
            return adv
        },

        notPaidAdvertisers() {
            let notPaid = [];
            this.advertisers.forEach(advertiser => {
                let ad = this.activeAdvertisements.find(advertisement => advertisement.advertiserId == advertiser.id);
                if (ad) {
                    if (ad.advPaymentMethod === "factuur" && ad.advState === "verkocht" && (ad.advPaid === false || ad.advPaid === null || ad.advPaid === undefined)) {
                        ad.adres = advertiser.adres;
                        ad.postalCode = advertiser.postalCode;
                        ad.city = advertiser.city;
                        ad.email = advertiser.email;
                        notPaid.push(ad);
                    }
                }

            });
            return notPaid;
        },


    },
    methods: {
        paid(bool) {
            if (bool == true) {
                this.editItem.advPaid = true;
            } else{
                this.editItem.advPaid = false;
            }
            this.saveAdvertisement(this.editItem);
            this.openDialog = false;
            this.editItem = {};
        },
        saveAdvertisement(item) {
            this.$store.commit("paperStore/saveAdvertisement", {
                advertisement: item,
            });
        },
        getStatusColor(item) {
            let color = "white"
            if (item.advPaid === false || item.advPaid === null || item.advPaid === undefined) {
                color = "red"
            } else {
                color = "green"
            }
            return color;
        },
        sendFac(){
            this.$store.commit("paperStore/sendFacturation", {
                ads: this.notPaidAdvertisers,
            });
        }

    }
}
</script>