<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Gebruiker aanpassen</span>
      </h1>
    </v-layout>
    <v-col cols="12" sm="6" v-if="!buzzy">
      <v-form ref="form">
        <v-text-field v-model="user.name" label="Naam" :rules="[rules.required]"></v-text-field>
        <v-text-field
          v-model="user.email"
          :rules="[rules.required,rules.validEmail]"
          label="E-mail"
          required
        ></v-text-field>
        <v-checkbox
          v-model="user.isExternal"
          label="Extern account (geen lid van de stichting)" 
        ></v-checkbox>
        <Permissions  :userPermissions="user.permissions" @update-permissions="onUpdatePermissions"></Permissions>
        <div class="text-center mb-2">
          <span class="center red--text" v-if="feedback">{{feedback}}</span>
        </div>
        <v-row justify="center" class="ma-5">
          <v-btn @click="editUser()" outlined rounded text class="ma-2">
            <span>Aanpassen</span>
            <v-icon small right>mdi-account-plus-outline</v-icon>
          </v-btn>
          <v-btn outlined rounded text class="ma-2">
            <router-link :to="{name: 'Users' }" class="black--text">
              <span>Terug</span>
              <v-icon small right>mdi-arrow-left-circle</v-icon>
            </router-link>
          </v-btn>
        </v-row>
      </v-form>
    </v-col>
    <v-progress-circular v-if="buzzy"
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
  </v-container>
</template>

<script>
import Permissions from './Permissions.vue';
export default {
  components: {
    Permissions
  },
  data() {
    return {
      feedback: null,
      rules: {
        required: value => !!value || "Verplicht",
        validEmail: v =>
          /.+@.+\..+/.test(v) || "E-mail voldoet niet aan het juiste formaat",
        min: v => v.length >= 8 || "Min 8 karakters"
      },
      buzzy: false

    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    onUpdatePermissions(permissions) {
      this.user.permissions = permissions;
    },

    editUser() {
      this.buzzy = true;
      this.$store.dispatch("editUser", this.user);
    }
  },
  created() {
    this.$store.dispatch("getUser", this.$route.params.uid);
  }
};
</script>

<style>
</style>