<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Over ons</span>
      </h1>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutEditModules">
      <v-flex class="ma-2" xs12 name="flexIntro">
        <v-card>
          <v-system-bar class="pa-4" color="#ebb63b">
            <span>
              <b>Introductie tekst Over ons</b>
            </span>
          </v-system-bar>
          <v-textarea
            class="pa-2"
            v-model="aboutUsIntro"
            disabled
            v-if="lockIntro"
          ></v-textarea>
          <v-textarea
            class="pa-2"
            v-model="newIntro"
            v-if="!lockIntro"
          ></v-textarea>
          <v-card-actions>
            <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
            <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
            <v-btn v-if="!lockIntro" @click="lockIntro = true">Annuleren</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 class="ma-2" name="flexPrinsTabs">
        <v-card>
          <v-system-bar flat color="#ebb63b">
            <span>
              <b>Prinsen carrousel</b>
            </span></v-system-bar
          >
          <v-tabs v-model="tab" align-with-title color="#cc212f">
            <v-tabs-slider color="#cc212f"></v-tabs-slider>
            <v-tab v-for="(prins, i) in contentAboutUs.carrousel" :key="i">
              {{ prins.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(prins, i) in contentAboutUs.carrousel" :key="i">
              <v-card flat>
                <v-row class="d-flex justify-space-around ma-3">
                  <img
                    v-if="prins.image"
                    :src="prins.foto"
                    max-width="80%"
                    class="img-circle"
                  />
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <p class="ahcGreen" style="white-space: pre-wrap">
                    {{ prins.story }}
                  </p>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions>
            <v-btn @click="changeCarrouselDialog = true"> aanpassen </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      transition="dialog-top-transition"
      max-width="900"
      v-model="changeCarrouselDialog"
    >
      <v-card v-if="!buzzy">
        <v-system-bar class="pa-4" color="#ebb63b">
          <span>
            <b>Prinsen carrousel aanpassen</b>
          </span>
        </v-system-bar>
        <v-alert
          outlined
          color="#ebb63b"
          class="ma-5"
          v-for="(prins, i) in contentAboutUs.carrousel"
          :key="i"
        >
          <div class="ma-3">
            <v-text-field
              v-model="prins.name"
              placeholder="Prins..., 1900 - 1903"
              label="Naam van de prins"
            >
            </v-text-field>
            <v-textarea
              v-model="prins.story"
              placeholder="Er was eens... blabla"
              label="Verhaaltje bij de prins"
            ></v-textarea>
            <div v-if="prins.image">
              <img
                :src="prins.foto"
                contain
                width="80%"
                class="img-circle carrouselImage"
              />
              <v-btn text @click="deleteImage(prins.id), (prins.image = false)"
                >foto verwijderen</v-btn
              >
            </div>
            <div v-if="!prins.image">
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                v-model="newImage"
                prepend-icon="mdi-camera"
                @change="addImage(prins.id), (prins.image = true)"
              ></v-file-input>
            </div>
            <v-btn text @click="deletePrins(prins.id)"
              >Prins verwijderen<v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-alert>
        <v-icon class="ma-5" @click="addPrins()" color="#cc212f"
          >mdi-plus</v-icon
        >
        <v-card-actions>
          <v-btn text @click="changeCarrouselDialog = false">Annuleren</v-btn>
          <v-btn text @click="updateCarrousel(), (buzzy = true)">Opslaan</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Carrousel aan het updaten....</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      lockIntro: true,
      newIntro: null,
      changeCarrouselDialog: false,
      buzzy: false,
      newImage: null,
      tab: null,
    };
  },
  computed: {
    aboutUsIntro() {
      return this.$store.state.intros.aboutUs;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
    contentAboutUs() {
      return this.$store.state.contentAboutUs;
    },
  },
  methods: {
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.aboutUsIntro;
    },
    updateIntro() {
      let intro = {
        name: "aboutUs",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
    updateCarrousel() {
      this.$store.dispatch("updateContentAboutUs");
      setTimeout(() => {
        this.changeCarrouselDialog = false;
        this.buzzy = false;
      }, 4000);
    },
    deleteImage(id) {
      let deleteRef = firebase.storage().ref(`images/aboutUs/carrousel/${id}`);
      deleteRef.delete();
    },
    addImage(id) {
      let file = this.newImage;
      let file_type = file.type;
      if (file_type && file_type.substr(0, 5) == "image") {
        let img = document.createElement("img");
        let reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let MAX_WIDTH = 200;
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              let storageRef = firebase
                .storage()
                .ref(`images/aboutUs/carrousel/${id}`);
              storageRef.put(blob);
            });
          };
        };
        reader.readAsDataURL(file);
      }
      this.newImage = null;
    },
    deletePrins(id) {
      for (let i = 0; i < this.contentAboutUs.carrousel.length; i++) {
        if (this.contentAboutUs.carrousel[i].id === id) {
          this.contentAboutUs.carrousel.splice(i, 1);
        }
      }
      this.deleteImage(id);
    },
    addPrins() {
      let prins = {
        id: Date.now(),
        image: false,
      };
      this.contentAboutUs.carrousel.push(prins);
    },
  },
  beforeCreate() {
    this.$store.dispatch("loadContentAboutUs");
  },
};
</script>


<style >
</style>
