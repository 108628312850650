<template>
  <v-container>
    <v-layout row wrap class="ma-3">
      <v-flex xs12 name="flexMembersOverview" v-if="advertisers">
        <v-flex class="ma-2" xs12>
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Overzicht adverteerders</b>
              </span>
              <v-spacer></v-spacer>
              <v-tooltip v-if="!personalOverView" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="addAdvertiser = true" color="#cc212f" v-bind="attrs" v-on="on"
                    v-if="!addAdvertiser">mdi-plus</v-icon>
                </template>
                <span>Adverteerder toevoegen</span>
              </v-tooltip>
            </v-system-bar>
            <v-form class="ma-3" v-if="addAdvertiser && !personalOverView">
              <v-text-field v-model="newAdvertiser.userId" label="Advertentienummer" required></v-text-field>

              <v-text-field v-model="newAdvertiser.name"
                label="Bedrijfsnaam (officieel, verschijnt ook op factuur!)"></v-text-field>
              <v-select label="Contactpersoon" v-model="newAdvertiser.contact" :items="users" color="#cc212f"
                :menu-props="{ top: true, offsetY: true }" light></v-select>
              <v-text-field v-model="newAdvertiser.adres" label="Adres" placeholder="Grote Markt 1"></v-text-field>
              <v-text-field v-model="newAdvertiser.postalCode" label="Postcode" placeholder="4561AA"></v-text-field>
              <v-text-field v-model="newAdvertiser.city" label="Plaats" placeholder="Hulst"></v-text-field>
              <v-text-field v-model="newAdvertiser.email" label="Email adres"
                placeholder="pietje@precies.nl"></v-text-field>
              <v-text-field v-model="newAdvertiser.phone" label="Telefoonnummer" placeholder="0612345678"></v-text-field>
              <v-textarea label="Notities" v-model="newAdvertiser.notes">
              </v-textarea>
              <v-btn class="ma-2" @click="saveNewAdvertiser" v-if="newAdvertiser.name && newAdvertiser.userId">
                Opslaan
              </v-btn>
              <v-btn class="ma-2" @click="(newAdvertiser = {}), (addAdvertiser = false)">
                Annuleren
              </v-btn>
            </v-form>
            <v-text-field v-if="!addAdvertiser" v-model="search" append-icon="mdi-magnify" label="Zoeken" class="ma-5"
              single-line hide-details></v-text-field>
            <v-data-table v-if="!addAdvertiser"
              :headers="personalOverView ? advertiserHeadersPersonal : advertiserHeaders" :items="advertisers"
              :single-expand="singleExpand" :expanded.sync="expanded" :search="search" sort-by="userId" item-key="id"
              show-expand hide-default-footer loading-text="De adverteerderlijst wordt ingeladen" disable-pagination>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="advertiserHeaders.length">
                  <v-alert outlined color="#ebb63b">
                    <v-card v-if="!editAdvertiserDiv" flat>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col class="d-flex justify-center">
                          <v-btn @click="
                            (editAdvertiserDiv = true),
                            (editAdvertiser = item)
                            ">
                            Gegevens aanpassen</v-btn>
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-btn v-if="activeEdition.title" @click="
                            advertisementDialog = true;
                          advertisementParent = item;
                          lookupAdvertisement()
                            ">Advertentie {{ activeEdition.title }}</v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span>Bedrijfsnaam: <b>{{ item.name }}</b></span>
                        </v-col>


                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span>Adres: <b>{{ item.adres }}</b></span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span>Postcode: <b>{{ item.postalCode }}</b></span>
                        </v-col>

                        <v-col>
                          <span>Plaats: <b>{{ item.city }}</b></span>
                        </v-col>
                        <v-col>
                          <span></span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span>Telefoonnummer: <b>{{ item.phone }}</b></span>
                        </v-col>
                        <v-col>
                          <span>Emailadres: <b>{{ item.email }}</b></span>
                        </v-col>
                        <v-col>
                          <span></span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span>Contactpersoon: <b>{{ item.contact }}</b></span>
                        </v-col>
                      </v-row>

                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <h3>Notities:</h3>
                          <span>{{ item.notes }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around my-3">
                        <v-col>
                          <v-expansion-panels flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <h3>Verkoopgeschiedenis <i>(Vanaf editie 2024...)</i></h3>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content v-if="item.salesRecord">
                                <span v-for="(record, i) in item.salesRecord" :key="i">{{ record }}<br /></span>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>



                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around my-3">
                        <v-col>
                          <v-expansion-panels flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <h3>Logboek</h3>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <span v-for="(log, l) in item.changeLog" :key="l"><i>{{ log }}</i><br /></span>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>



                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card v-if="editAdvertiserDiv" flat>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field v-model="editAdvertiser.name" label="Bedrijfsnaam"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-switch label="Actief" v-model="editAdvertiser.active" v-if="!personalOverView"></v-switch>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field v-model="editAdvertiser.adres" label="Adres"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field v-model="editAdvertiser.postalCode" label="Postcode"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field v-model="editAdvertiser.city" label="Plaats"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field v-model="editAdvertiser.phone" label="Telefoonnummer"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field v-model="editAdvertiser.email" label="Emailadres"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col v-if="!personalOverView">
                          <v-select label="Contactpersoon" v-model="editAdvertiser.contact" :items="users" color="#cc212f"
                            :menu-props="{ top: true, offsetY: true }" light v-if="!personalOverView"></v-select>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-textarea v-model="editAdvertiser.notes" label="Notities"></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center ma-3">
                        <v-btn class="mx-2" @click="
                          (editAdvertiserDiv = false), (editAdvertiser = {})
                          ">Annuleren</v-btn>
                        <v-btn class="mx-2" @click="saveAdvertiser">Opslaan</v-btn>
                        <v-btn class="mx-2" @click="deleteConfirmationDialog = true" v-if="!personalOverView">Adverteerder
                          verwijderen</v-btn>
                      </v-row>
                    </v-card>
                  </v-alert>
                </td>
              </template>
              <template v-if="!personalOverView" v-slot:[`item.active`]="{ item }">
                <v-icon :color="getActiveColor(item.active)" dark>
                  mdi-circle
                </v-icon>
              </template>
              <template v-if="!advertisementDialog" v-slot:[`item.status`]="{ item }">
                <v-icon :color="getStatusColor(item)" dark>
                  mdi-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-overlay :value="buzzy" opacity="0.8">
      <span>Adverteerder wordt opgeslagen...</span>
      <br />
      <v-progress-circular indeterminate :size="70" :width="7" color="#cc212f" class="mx-auto"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="deleteConfirmationDialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          Weet je zeker dat je de adverteerder wil verwijderen?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="deleteConfirmationDialog = false">
            Annuleren
          </v-btn>
          <v-btn color="red-darken-1" variant="text"
            @click="deleteConfirmationDialog = false, deleteAdvertiser(editAdvertiser.id)">
            Verwijderen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="advertisementDialog" persistent width="auto">
      <v-card>
        <v-card-title class="text-h5">
          Advertentie {{ activeEdition.title }} - {{ advertisementParent.name }}
        </v-card-title>
        <v-card-text v-if="advertisement">
          <div class="ma-5">
            <h3>Advertentie gegevens</h3>
            <v-select label="status" v-model="advertisement.advState"
              :items="['open', 'verkocht', 'niet verkocht']"></v-select>
            <v-select label="Prijs" v-if="advertisement.advState === 'verkocht'" v-model="advertisement.advPrice"
              :items="priceOptions" @click="clearFormat"></v-select>
            <v-select label="Betaalwijze" v-if="advertisement.advState === 'verkocht'"
              v-model="advertisement.advPaymentMethod" :items="['contant', 'factuur', 'gratis']"></v-select>
            <v-select label="Formaat" v-if="advertisement.advPrice" v-model="advertisement.advFormat"
              :items="getFormatOptions(advertisement.advPrice)"></v-select>
            <v-text-field v-if="advertisement.advState === 'verkocht'" v-model="advertisement.advColor" label="Kleur"
              readonly></v-text-field>
            <v-select label="Aanpassingen" v-if="advertisement.advState === 'verkocht'" v-model="advertisement.advType"
              :items="['Idem vorig jaar', 'Aanpassing nodig / nieuw']"></v-select>

          </div>

          <v-divider></v-divider>

          <div class="ma-5">
            <h3>Opmerkingen</h3>
            <v-textarea v-model="advertisement.advNotes" placeholder="Prijs menu aanpassen naar €11,11"></v-textarea>
            <v-textarea v-if="advertisement.advRedactieNote && advertisement.advRedactieNote.length > 0"
              v-model="advertisement.advRedactieNote" readonly></v-textarea>
            <div v-if="advertisement.advContactQuestions && advertisement.advContactQuestions.length > 0">
              <v-divider></v-divider>
              <h3 class="mb-3">Vragen van de redactie</h3>
              <v-row class=" mb-1" v-for="(question, i) in advertisement.advContactQuestions" :key="i">
                <span class="mx-3">{{ question.msg }} - {{ question.status }}</span>
                <v-btn  v-if="question.status == 'open'"
                  @click="markAsFulfilled(advertisement, i)"><v-icon>mdi-thumb-up</v-icon>Opgelost</v-btn>
              </v-row>

            </div>
            <v-divider></v-divider>

            <h3>Documenten</h3>
            <v-btn v-if="!addAdvertisementDoc" @click="addAdvertisementDoc = true">Nieuw document uploaden</v-btn>
            <br />
            <span v-if="!advertisement.docs || advertisement.docs.length == 0 && !addAdvertisementDoc">
              Er zijn voor deze advertentie geen documenten geupload.
            </span>
            <v-form class="pa-2" v-if="addAdvertisementDoc">
              <h4>Nieuw document uploaden</h4>
              <v-text-field label="Titel (verplicht)" v-model="newDoc.title"></v-text-field>
              <v-textarea label="Beschrijving (optioneel)" v-model="newDoc.description">
              </v-textarea>
              <v-file-input label="te uploaden document: " show-size v-model="newDoc.doc"></v-file-input>

              <v-btn @click="(addAdvertisementDoc = false), (newDoc = {})">Annuleren</v-btn>
              <v-btn v-if="newDoc.title && newDoc.doc" @click="saveAdvertisementDoc()">Opslaan</v-btn>
            </v-form>
            <v-list v-if="advertisement.docs && advertisement.docs.length > 0 && !addAdvertisementDoc" three-line>
              <v-list-item v-for="(doc, i) in advertisement.docs" :key="i">
                <v-list-item-icon @click="downloadFile(doc.title, doc.id, doc.type)">
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle><small>{{ doc.creationDate }} - {{ doc.creator }}</small></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon @click="deleteDoc(doc.id)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

          </div>
          <v-divider></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" v-if="!addAdvertisementDoc" @click="saveAdvertisement">
            Opslaan
          </v-btn>
          <v-btn variant="text" @click="advertisementDialog = false, advertisement = null">
            Annuleren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import firebase from "firebase";
import Axios from "axios";
export default {
  data() {
    return {
      advertiserHeaders: [
        { text: "Nr", value: "userId", align: "start", },
        { text: "Verkocht", value: "status" },
        { text: "Bedrijf", value: "name" },
        { text: "Verantwoordelijke", value: "contact" },
        { text: "Actief", value: "active" },
        { text: "", value: "data-table-expand" },
      ],
      advertiserHeadersPersonal: [
        { text: "Nr", value: "userId", align: "start", },
        { text: "Verkocht", value: "status" },
        { text: "Bedrijf", value: "name" },
        { text: "", value: "data-table-expand" },
      ],
      editAdvertiserDiv: false,
      editAdvertiser: {},
      newAdvertiser: {},
      addAdvertiser: false,
      search: "",
      singleExpand: false,
      expanded: [],
      buzzy: false,
      deleteConfirmationDialog: false,
      advertisementParent: {},
      advertisementDialog: false,
      advertisement: null,
      addAdvertisementDoc: false,
      newDoc: {},
    };
  },
  props: {
    personalOverView: {
      type: Boolean,
      default: false,
    },
    advertisers: {
      type: Array,
      default: () => {
        return [];
      },
    },
    activeEdition: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    userName() {
      return this.$store.state.currentUser.name;
    },
    users() {
      let names = [];
      for (let i = 0; i < this.$store?.state?.users?.length; i++) {
        names.push(this.$store.state.users[i].name);
      }
      return names;
    },
    advertiseCategories() {
      return this.$store.state.paperStore.advertiseCategories;
    },
    activeAdvertisements() {
      return this.$store.state.paperStore.activeAdvertisements;
    },
    priceOptions() {
      let prices = [];
      this.advertiseCategories.forEach(category => {
        prices.push(category.price);
      });
      return prices;
    }

  },
  methods: {
    saveAdvertiser() {
      this.buzzy = true;
      let editMsg = `Adverteerder is aangepast door ${this.userName
        } op ${new Date().toLocaleDateString()}`;
      this.editAdvertiser.changeLog.push(editMsg);
      this.$store.commit("paperStore/saveAdvertiser", {
        advertiser: this.editAdvertiser,
      });
      setTimeout(() => {
        this.editAdvertiserDiv = false;
        this.editAdvertiser = {};
        this.buzzy = false;
      }, 1000);
    },
    saveNewAdvertiser() {
      this.buzzy = true;
      this.newAdvertiser.changeLog = [];
      this.newAdvertiser.id = Date.now();
      this.newAdvertiser.active = true;
      let createdMsg = `Adverteerder is aangemaakt door ${this.userName
        } op ${new Date().toLocaleDateString()}`;
      this.newAdvertiser.changeLog.push(createdMsg);
      this.$store.commit("paperStore/saveAdvertiser", {
        advertiser: this.newAdvertiser,
      });
      setTimeout(() => {
        this.newAdvertiser = {};
        this.addAdvertiser = false;
        this.buzzy = false;
      }, 1000);
    },
    deleteAdvertiser(id) {
      this.$store.commit("paperStore/deleteAdvertiser", {
        id: id,
      });
      setTimeout(() => {
        this.editAdvertiserDiv = false;
        this.editAdvertiser = {};
        this.buzzy = false;
      }, 1000);
    },
    getActiveColor(active) {
      if (active) {
        return "green";
      } else {
        return "red";
      }
    },

    getStatusColor(item) {
      let color = "white"
      if (this.activeEdition.saleSeason) {
        let advertisement = this.activeAdvertisements.find(advertisement => advertisement.advertiserId == item.id);
        if (advertisement) {
          switch (advertisement.advState) {
            case "open":
              color = "orange"
              break;
            case "verkocht":
              color = "green"
              break;
            case "niet verkocht":
              color = "red"
              break;
            default:
              break;
          }
        } else {
          color = "orange"
        }
      }
      return color;
    },
    lookupAdvertisement() {
      try {
        //check if there is a active advertisement with the id of the advertiser
        let advertisement = this.activeAdvertisements.find(advertisement => advertisement.advertiserId == this.advertisementParent.id);
        if (advertisement) {
          this.advertisement = JSON.parse(JSON.stringify(advertisement));
        } else {
          this.advertisement = {
            id: Date.now(),
            advertiserId: this.advertisementParent.id,
            name: this.advertisementParent.name,
            advertiserUserId: this.advertisementParent.userId,
            editionId: this.activeEdition.id,
            advState: "open",
            advRedactieNote: "",
            placedOnPage: "",
            readyForPlacement: false,
            readyForEditing: false,
            placed: false,
            contact: this.advertisementParent.contact,
            docs: [],
          };
        }
      } catch (error) {
        console.log("lookupAdvertisement went wrong", error);
      }
    },
    saveAdvertisement() {
      this.buzzy = true;
      if (this.advertisement.advState === 'open' || this.advertisement.advState === 'niet verkocht') {
        console.log('open of niet verkocht')
        this.advertisement.advPrice = null;
        this.advertisement.advFormat = null;
        this.advertisement.advPaymentMethod = null;
        this.advertisement.advType = null;
      }
      let adv = this.advertisementParent

      if (!adv.salesRecord || !Array.isArray(adv.salesRecord)) {
        adv.salesRecord = []
      }
      let salesRecordMsg = ''
      switch (this.advertisement.advState) {
        case "open":
          salesRecordMsg = `${this.activeEdition.title} - open`
          break;
        case "verkocht":
          salesRecordMsg = `${this.activeEdition.title} - verkocht - ${adv.contact} -  ${this.advertisement.advPrice ?? ''} - ${this.advertisement.advFormat ?? ''} - ${this.advertisement.advColor ?? ''} - ${this.advertisement.advPaymentMethod ?? ''}`
          break;
        case "niet verkocht":
          salesRecordMsg = `${this.activeEdition.title} - niet verkocht - ${adv.contact}`
          break;

        default:
          break;
      }
      //check if there is already a string containing the active edition title in the salesRecord array
      let index = adv.salesRecord.findIndex(record => record.includes(this.activeEdition.title))
      if (index > -1) {
        //if there is, replace the string with the new one
        adv.salesRecord.splice(index, 1, salesRecordMsg)
      } else {
        //if there is not, push the new string to the array
        adv.salesRecord.push(salesRecordMsg)
      }

  
      this.$store.commit("paperStore/saveAdvertiser", {
        advertiser: adv,
      })

      this.$store.commit("paperStore/saveAdvertisement", {
        advertisement: this.advertisement,
      });
      setTimeout(() => {
        this.advertisementDialog = false;
        this.advertisement = {};
        this.buzzy = false;
      }, 1000);
    },


    getFormatOptions(price) {
      let formats = [];
      this.advertiseCategories.forEach(category => {
        if (category.price == price) {
          formats = category.formats;
        }
      });
      return formats;
    },

    clearFormat() {
      this.advertisement.advFormat = "";
    },

    saveAdvertisementDoc() {
      try {
        this.newDoc.id = Date.now();
        this.newDoc.creationDate = new Date().toLocaleDateString()
        this.newDoc.creator = this.userName;
        this.newDoc.type = this.newDoc.doc.name.split(".").pop();
        let storageRef = firebase.storage().ref();
        storageRef
          .child(`docs/Edition_${this.activeEdition.title}/${this.advertisementParent.userId}/${this.newDoc.id}`)
          .put(this.newDoc.doc);
        this.advertisement.docs.push(this.newDoc);
        this.$store.commit("paperStore/saveAdvertisement", {
          advertisement: this.advertisement,
        });
        this.newDoc = {};
        this.addAdvertisementDoc = false;

      } catch (error) {
        console.log(error.message);
      }
    },

    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      } else if (file_type == "jpg") {
        metadata = "image/jpeg";
      } else if (file_type == "png") {
        metadata = "image/png";
      } else if (file_type == "gif") {
        metadata = "image/gif";
      } else if (file_type == "txt") {
        metadata = "text/plain";
      }

      let filename = title;
      let file = firebase
        .storage()
        .ref(`docs/Edition_${this.activeEdition.title}/${this.advertisementParent.userId}/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
    deleteDoc(id) {
      for (let i = 0; i < this.advertisement.docs.length; i++) {
        if (this.advertisement.docs[i].id === id) {
          let storageRef = firebase.storage().ref();
          storageRef
            .child(`docs/Edition_${this.activeEdition.title}/${this.advertisementParent.userId}/${id}`)
            .delete();

          this.advertisement.docs.splice(i, 1);
          this.$store.commit("paperStore/saveAdvertisement", {
            advertisement: this.advertisement,
          });
        }
      }

    },

    markAsFulfilled(item, index) {
      item.advContactQuestions[index].status = "Opgelost";
      item.openQuestion = false
      item.advContactQuestions.forEach(question => {
        if (question.status == "open") {
          item.openQuestion = true;
        }
      });
      this.$store.commit("paperStore/saveAdvertisement", {
        advertisement: item,
      });
    },



   

  },
  //set a watch on this.advertisement.advPrice to set the color
  watch: {
    "advertisement.advPrice": function (newVal) {
      this.advertiseCategories.forEach(category => {
        if (category.price == newVal) {
          this.advertisement.advColor = category.colors;
        }
      });
    },
  },

};
</script>

<style scoped></style>