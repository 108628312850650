import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyBY8MqPRXRMzCM4nLI_cGmC4zn6qR57A_4",
    authDomain: "ahcsite-a5226.firebaseapp.com",
    projectId: "ahcsite-a5226",
    storageBucket: "ahcsite-a5226.appspot.com",
    messagingSenderId: "424963527959",
    appId: "1:424963527959:web:09f907b2ec9ebebc03d352",
    measurementId: "G-BSJHSCMJHF"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

firebase.analytics()

export default firebaseApp

