<template>
  <v-container>
    <h1 class="ma-4 d-flex">
      <span class="ahcRed">Persoonlijke pagina</span>
    </h1>
    <v-layout row wrap class="ma-3" name="layoutProfileModules">
      <v-flex xs12 md5 name="flexInternalPassword">
        <v-flex class="ma-2" xs12 name="flexInternalUse">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed">
                <b>Informatie intern gebruik</b>
              </span>
            </v-system-bar>
            <v-card-text v-if="!user">
              <v-row class="d-flex justify-space-around mb-6">
                <v-progress-circular
                  class="ma-5 ahcRed"
                  :size="70"
                  :width="7"
                  indeterminate
                ></v-progress-circular>
              </v-row>
              <v-row class="d-flex justify-space-around mb-6">
                <h3>Inladen informatie...</h3>
              </v-row>
            </v-card-text>
            <v-card-text v-if="user">
              <v-layout row class="d-flex align-center pa-2">
                <v-flex xs11>
                  <span class="ahcGreen" v-if="!editNumber">
                    Telefoonnummer:
                    <b>{{ user.phoneNumber }}</b>
                  </span>
                  <v-text-field
                    v-if="editNumber"
                    v-model="user.phoneNumber"
                    label="Telefoonnummer"
                    class="ahcGreen"
                  ></v-text-field>
                </v-flex>
                <v-flex xs1>
                  <v-icon
                    v-if="!editNumber"
                    class="ma-3"
                    @click="(editNumber = !editNumber), (change0 = true)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    v-if="editNumber"
                    class="ma-3"
                    @click="
                      (editNumber = !editNumber),
                        editProfile(),
                        (change0 = false)
                    "
                    >mdi-content-save-edit</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-layout row class="d-flex align-center pa-2">
                <v-flex xs11>
                  <span class="ahcGreen" v-if="!editEmail">
                    Email:
                    <b>{{ user.email }}</b>
                  </span>
                  <v-text-field
                    v-if="editEmail"
                    v-model="user.email"
                    label="emailadres"
                    class="ahcGreen"
                    :rules="[
                      (v) =>
                        /.+@.+\..+/.test(v) ||
                        'E-mail voldoet niet aan het juiste formaat',
                    ]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs1>
                  <v-icon
                    v-if="!editEmail"
                    class="ma-3"
                    @click="(editEmail = !editAdres), (change1 = true)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    v-if="editEmail"
                    class="ma-3"
                    @click="
                      (editEmail = !editEmail), editProfile(), (change1 = false)
                    "
                    >mdi-content-save-edit</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-layout row class="d-flex align-center pa-2">
                <v-flex xs11>
                  <span class="ahcGreen" v-if="!editAdres">
                    Adres:
                    <b>{{ user.adres }}</b>
                  </span>
                  <v-text-field
                    v-if="editAdres"
                    v-model="user.adres"
                    label="Adres"
                    class="ahcGreen"
                    placeholder="Grote Markt 1, 4561AA Hulst"
                  ></v-text-field>
                </v-flex>
                <v-flex xs1>
                  <v-icon
                    v-if="!editAdres"
                    class="ma-3"
                    @click="(editAdres = !editAdres), (change2 = true)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon
                    v-if="editAdres"
                    class="ma-3"
                    @click="
                      (editAdres = !editAdres), editProfile(), (change2 = false)
                    "
                    >mdi-content-save-edit</v-icon
                  >
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex class="ma-2" xs12 name="flexPassword">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed">
                <b>Wachtwoord veranderen</b>
              </span>
            </v-system-bar>
            <v-form ref="form" align="center" class="ma-5">
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                label="Wachtwoord"
                @click:append="show = !show"
                v-model="password"
              ></v-text-field>
              <div class="text-center mb-2">
                <span class="center red--text" v-if="negFeedback">{{
                  negFeedback
                }}</span>
              </div>
              <div class="text-center mb-2">
                <span class="center green--text" v-if="posFeedback">{{
                  posFeedback
                }}</span>
              </div>
              <v-btn @click="updatePassword" class="ma-5">
                <span>Wachtwoord aanpassen</span>
                <v-icon small right>mdi-account-edit-outline</v-icon>
              </v-btn>
            </v-form>
          </v-card>
        </v-flex>
      </v-flex>
      <!-- <v-flex class="ma-2" xs12 md6 name="flexWebsite">
        <v-card>
          <v-system-bar class="pa-4" color="#ebb63b">
            <span class="ahcRed">
              <b>Stuk website</b>
            </span>
          </v-system-bar>
        </v-card>
      </v-flex> -->
    </v-layout>
    <v-overlay :value="buzzy" opacity="0.2">
      <v-progress-circular
        indeterminate
        :size="70"
        :width="7"
        color="green"
        class="mx-auto"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      editNumber: false,
      editEmail: false,
      editAdres: false,
      buzzy: false,
      password: "",
      show: false,
      negFeedback: null,
      posFeedback: null,
      change0: false,
      change1: false,
      change2: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    editProfile() {
      this.$store.dispatch("editProfile", this.user);
    },
    updatePassword() {
      if (this.password.length < 8) {
        this.negFeedback = `Het wachtwoord moet uit minimaal 8 tekens bestaan`;
        this.posFeedback = null;
      } else {
        this.negFeedback = null;
        this.buzzy = true;
        let user = {
          id: firebase.auth().currentUser.uid,
          password: this.password,
        };
        let updateUser = firebase.functions().httpsCallable("updatePassword");
        updateUser({ user: user }).then((result) => {
          if (result.data.succes) {
            this.buzzy = false;
            this.posFeedback = `Uw wachtwoord is succesvol gewijzigd`;
          }
        });
        this.password = "";
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.change0 || this.change1 || this.change2) {
      const answer = window.confirm(
        "Weet je zeker dat je de pagina wilt verlaten zonder de wijzigingen op te slaan?"
      );
      if (answer) {
        this.change = false;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
.ahcGreen {
  color: #305645;
}
</style>