<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">UBPF</span>
      </h1>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutEditModules">
      <v-flex xs12 name="flexIntro">
        <v-flex class="ma-2" xs12>
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Introductie tekst Ubpffestival</b>
              </span>
            </v-system-bar>
            <v-textarea
              class="pa-2"
              v-model="ubpfIntro"
              disabled
              v-if="lockIntro"
            ></v-textarea>
            <v-textarea
              class="pa-2"
              v-model="newIntro"
              v-if="!lockIntro"
            ></v-textarea>
            <v-card-actions>
              <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
              <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
              <v-btn v-if="!lockIntro" @click="lockIntro = true"
                >Annuleren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-flex>
      <!-- <v-flex xs12 name="flexOverviewOrders">
        <v-flex class="ma-2">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Overzicht bestellingen UBPF 2021</b>
              </span>
            </v-system-bar>
            <v-card-subtitle>
              Aantal bestelde pakketten:
              <b>{{ totalOrders }}</b>
              <small>
                (uit
                <b>{{ totalCustomers }}</b> bestellingen)
              </small>
              <br />Totale opbrengst:
              <b>€{{ totalProfit }}</b>
              <br />Aantal pakketten op zaterdag:
              <b>{{ totalSaturday }}</b>
              <small>
                (uit
                <b>{{ totalCustomersSaturday }}</b> bestellingen)
              </small>
              <br />Aantal pakketten op zondag:
              <b>{{ totalSunday }}</b>
              <small>
                (uit
                <b>{{ totalCustomersSunday }}</b> bestellingen)
              </small>
            </v-card-subtitle>
            <v-card-text>
              <template class="ma-4">
                <v-data-table
                  :headers="headers"
                  :items="orders"
                  :items-per-page="5"
                  class="elevation-1"
                ></v-data-table>
              </template>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-flex> -->
      <v-flex xs12 name="flexOverviewBrewery's">
        <brewery-info />
      </v-flex>
    </v-layout>

    <!-- <v-btn @click="processCollections()">start</v-btn> -->
  </v-container>
</template>


<script>
import BreweryInfo from "./BreweryInfo.vue";
export default {
  components: {
    BreweryInfo,
  },
  data() {
    return {
      headers: [
        {
          text: "Voornaam",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Achternaam", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Aantal", value: "amount" },
        { text: "Bedrag", value: "toPay" },
        { text: "Ophaalmoment", value: "collectDate" },
      ],
      newImageInput: null,
      lockIntro: true,
      newIntro: null,
    };
  },
  computed: {
    orders() {
      return this.$store.state.orders;
    },
    totalOrders() {
      let total = 0;
      for (let i = 0; i < this.orders.length; i++) {
        total += parseInt(this.orders[i].amount);
      }
      return total;
    },
    totalProfit() {
      let total = 0;
      for (let i = 0; i < this.orders.length; i++) {
        total += parseInt(this.orders[i].toPay);
      }
      return total;
    },
    totalSaturday() {
      let totalSaturday = 0;
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].collectDate == "Zaterdag 15 mei") {
          totalSaturday += parseInt(this.orders[i].amount);
        }
      }
      return totalSaturday;
    },
    totalSunday() {
      let totalSunday = 0;
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].collectDate == "Zondag 16 mei") {
          totalSunday += parseInt(this.orders[i].amount);
        }
      }
      return totalSunday;
    },
    totalCustomers() {
      let total = 0;
      for (let i = 0; i < this.orders.length; i++) {
        total += 1;
      }
      return total;
    },
    totalCustomersSaturday() {
      let totalSaturday = 0;
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].collectDate == "Zaterdag 15 mei") {
          totalSaturday += 1;
        }
      }
      return totalSaturday;
    },
    totalCustomersSunday() {
      let totalSunday = 0;
      for (let i = 0; i < this.orders.length; i++) {
        if (this.orders[i].collectDate == "Zondag 16 mei") {
          totalSunday += 1;
        }
      }
      return totalSunday;
    },

    ubpfIntro() {
      return this.$store.state.intros.ubpf;
    },
  },
  methods: {
    processCollections() {
      this.$store.dispatch("processCollections");
    },
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.ubpfIntro;
    },
    updateIntro() {
      let intro = {
        name: "ubpf",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
    saveImage() {
      this.$store.dispatch("saveImage", {
        label: "ubpf",
        image: this.newImageInput,
      });
      this.newImageInput = null;
    },
  },
  created() {
    this.$store.dispatch("loadOrders");
  },
};
</script>

<style>
</style>