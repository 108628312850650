<template>
      <v-card>
        <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Declaratie formulier</b>
              </span>
            </v-system-bar>
        <v-stepper v-model="declarationStepper" vertical v-if="declaration && accountancy && availableYears.length > 0 && !buzzy">
          <!-- Description -->
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 1"
            step="1"
            >Korte beschrijving:</v-stepper-step
          >

          <v-stepper-content step="1">
            <v-text-field
              label="Beschrijving"
              v-model="declaration.description"
            ></v-text-field>
            <v-btn
              v-if="declaration.description"
              color="#ebb63b"
              @click="declarationStepper = 2"
              >Volgende</v-btn
            >
          </v-stepper-content>

          <!-- Amount -->
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 2"
            step="2"
            >Bedrag:
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-text-field
              v-model="declaration.amount"
              label="Bedrag"
              type="number"
              >€</v-text-field
            >
            <v-btn
              v-if="declaration.amount"
              color="#ebb63b"
              @click="declarationStepper = 3"
              >Volgende</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- directPay -->
          <v-stepper-step
            :complete="declarationStepper > 3"
            step="3"
            color="#ebb63b"
          >
            Te betalen aan:
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-radio-group
              v-model="declaration.directPay"
              color="#cc212f"
              light
            >
              <v-radio :value="0" label="Rechtstreeks aan de leverancier."></v-radio>
              <v-radio :value="1" label="Aan mij."></v-radio>
            </v-radio-group>
            <div v-if="declaration.directPay == 1">
              <v-switch
                label="Heb je een nieuw rekening nummer?"
                v-model="declaration.newAccountnumber"
                column
                justify="center"
              ></v-switch>
            </div>
            <v-text-field
              v-model="declaration.accountnumber"
              label="Rekeningnummer"
              v-if="declaration.newAccountnumber"
            >
            </v-text-field>
            <v-btn
              color="#ebb63b"
              @click="declarationStepper = 4"
              v-if="
                declaration.directPay == 0 ||
                !declaration.newAccountnumber ||
                declaration.accountnumber
              "
              >Volgende</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- Add receipt? -->
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 4"
            step="4"
            >Factuur toevoegen</v-stepper-step
          >
          <v-stepper-content step="4">
            <v-file-input
              v-model="declaration.receipt"
              prepend-icon="mdi-camera"
              label="factuur"
            ></v-file-input>

            <v-btn
              v-if="declaration.receipt"
              color="#ebb63b"
              @click="declarationStepper = 5"
              >Volgende</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 5"
            step="5"
            >Boekjaar selecteren</v-stepper-step
          >
          <v-stepper-content step="5">
            <v-radio-group
              v-model="declaration.yearIndex"
              color="#cc212f"
              light
            >
              <v-radio v-for="(year, i) in availableYears"  :key="i" :value="year.value" :label="year.text"></v-radio>
            </v-radio-group>

            <v-btn
              v-if="declaration.yearIndex != null"
              color="#ebb63b"
              @click="declarationStepper = 6"
              >Volgende</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 6"
            step="6"
            >Commissie selecteren</v-stepper-step
          >
          <v-stepper-content step="6">
            <v-radio-group
              v-model="declaration.wg"
              color="#cc212f"
              light
            >
              <v-radio v-for="(com, i) in availableCommissions"  :key="i" :value="com.value" :label="com.text"></v-radio>
            </v-radio-group>

            <v-btn
              v-if="declaration.wg"
              color="#ebb63b"
              @click="addDeclaration()"
              >Declaratie indienen</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>
        </v-stepper>
        <v-progress-circular v-if="!accountancy || buzzy"
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
            <span v-else-if="availableYears.length == 0">Geen declaraties toegestaan, neem contact op met de penningmeester</span>
      </v-card>
      
</template>
<script>
import firebase from "firebase";
export default {
    name: "declaration-form",
  data() {
    return {
      declaration: {},
      declarationStepper: 1,
      buzzy: false,
    };
  },
  computed: {
    accountancy() {
      return this.$store.state.accountancy;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    availableYears() {
      let years = [];
        this.accountancy?.years.forEach(year => {
            if(!year.locked){
                years.push({
                    text: year.title,
                    value: year.index
                });
            }
        });
        return years;
    },
    availableCommissions() {
      let wgs = [];
      this.accountancy?.years.forEach(year => {
        if (year.index == this.declaration.yearIndex) {
          year.workgroups.forEach(wg => {
            wgs.push({
              text: wg.title,
              value: wg.index,
            });
          });
        }
      });
      return wgs;
    },
  },
  methods: {
    
    addDeclaration() {
      try {
        this.buzzy = true;
        let id = Date.now();
        this.declaration.id = id;
        let params = this.declaration.wg;
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        let amount = 0;
        this.declaration.creationDate = dd + "/" + mm + "/" + yyyy;
        this.declaration.creator = this.currentUser.name;
        this.declaration.creatorMail = this.currentUser.email;
        this.declaration.receiptType = this.declaration.receipt.name
          .split(".")
          .pop();
        this.declaration.amount = amount - this.declaration.amount;
        let storageRef = firebase.storage().ref();
        if (this.declaration.receipt.type.substr(0, 5) == "image") {
          let img = document.createElement("img");
          let reader = new FileReader();
          reader.onload = function (e) {
            img.src = e.target.result;
            img.onload = function () {
              let MAX_WIDTH = 1200;
              let width = img.naturalWidth;
              let height = img.naturalHeight;
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
              let canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              let ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);
              canvas.toBlob((blob) => {
                storageRef.child(`accountancy/C${params}/${id}`).put(blob);
              });
            };
          };
          reader.readAsDataURL(this.declaration.receipt);
        } else {
          storageRef
            .child(`accountancy/C${params}/${id}`)
            .put(this.declaration.receipt);
        }
        delete this.declaration.receipt;
        setTimeout(() => {
          this.$store.dispatch("addDeclaration", {
            year: this.declaration.yearIndex,
            wg: this.declaration.wg,
            declaration: this.declaration,
            userEmail: this.currentUser.email,
          });
          this.declaration = {};
          this.declarationStepper = 1;
          this.buzzy = false;
        }, 3000);
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};

</script>