<template>
  <div class="login">
    <h1 class="subheading grey--text" align="center">Login</h1>
    <v-container class="ma-5" fluid>
      <v-row justify="space-around">
        <v-form ref="form" align="center">
          <v-text-field v-model="email" label="E-mail"></v-text-field>
          <v-text-field
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            label="Wachtwoord"
            @click:append="show = !show"
            v-model="password"
          ></v-text-field>
          <div class="text-center mb-2">
            <span class="center red--text" v-if="feedback">{{feedback}}</span>
          </div>
          <v-btn @click="login" color="light-green" class="mr-4 grey--text">Inloggen</v-btn>
          <v-btn @click="resetDialog = true" class="mr-4 grey--text">Wachtwoord vergeten</v-btn>
        </v-form>
      </v-row>
    </v-container>
    <v-dialog v-model="resetDialog" max-width="350">
      <v-card>
        <v-card-title class="headline">Wilt u een nieuw wachtwoord aanvragen?</v-card-title>
        <v-card-text>Na het opgeven van uw e-mail adres ontvangt u een link om uw wachtwoord te wijzigen. Deze link is maximaal 60 minuten geldig.</v-card-text>
        <v-card-text>
          <v-text-field v-model="resetEmail" label="Uw e-mail adres: " class="grey--text"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="resetDialog = false">Annuleren</v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="(resetDialog = false),resetPassword(resetEmail)"
          >Aanvragen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from "../../firebase/init";

export default {
  name: "Login",
  data() {
    return {
      show: false,
      email: null,
      resetEmail: null,
      resetDialog: false,
      password: null,
      feedback: null
    };
  },
  methods: {
    login() {
      if (this.email && this.password) {
        if (/\S+@\S+\.\S+/.test(this.email)) {
          firebase
            .auth()
            .signInWithEmailAndPassword(this.email, this.password)
            .then(() => {
              let user = firebase.auth().currentUser;
              this.$store.dispatch("getCurrentUser", user.uid);
            })
            .then(() => {
              this.$router.push({ name: "Profile" });
            })
            .catch(() => {
              this.feedback =
                "Deze combinatie van email en wachtwoord is onbekend";
            });

          this.feedback = null;
        } else {
          this.feedback = "Dit is geen geldig email adres";
        }
      } else {
        this.feedback = "Vul alle velden in!";
      }
    },
    resetPassword(email) {
      firebase.auth().sendPasswordResetEmail(email);
      this.resetEmail = null;
    }
  }
};
</script>

<style>
</style>