<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1>
        <span class="ahcRed">Product overzicht</span>
      </h1>
      <v-spacer></v-spacer>
      <v-icon @click="toNewProduct" size="50" color="#cc212f">mdi-plus</v-icon>
    </v-layout>
    <v-card class="my-3">
      <v-system-bar class="pa-4" color="#ebb63b">
        <span class="ahcRed subheading"> </span>
      </v-system-bar>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Zoeken"
        class="ma-5"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :headers="productHeaders"
        :items="products"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :search="search"
        item-key="id"
        show-expand
        hide-default-footer
        disable-pagination
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-alert class="my-2" outlined color="#ebb63b">
              <v-card v-if="!editProductDiv" flat>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <span
                      ><b>Naam:</b>{{ item.title }}</span
                    >
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-icon @click="editProductDiv = true">mdi-cog</v-icon>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <span
                      ><b>Verkoop prijs:</b> €{{ item.price }}</span
                    >
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <span
                      ><b>Beschrijving:</b> </span
                    >
                    <div style="word-break: break-word; overflow-wrap: break-word; white-space: pre-wrap">{{ item.description }}</div>
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <span
                      ><b>Notities / bestel historie:</b> </span
                    >
                    <div style="word-break: break-word; overflow-wrap: break-word; white-space: pre-wrap">{{ item.suppliers }}</div>
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card class="mx-3" flat>
                      <h4 class="mx-3">Overzicht voorraad</h4>
                      <v-chip
                        color="#ebb63b"
                        text-color="#cc212f"
                        class="ma-3"
                        v-for="(loc, m) in stock"
                        :key="m"
                      >
                        <b
                          >{{ loc.title }}:
                          {{ getStockAmount(loc.title, item.id) }}</b
                        >

                        <v-icon
                          @click="
                            (tuneStockDialog = true),
                              (editStock.fromTitle = loc.title),
                              (editStock.itemTitle = item.title),
                              (editStock.itemID = item.id)
                          "
                          class="ml-4"
                          >mdi-tune-variant</v-icon
                        >
                      </v-chip>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around my-3">
                  <v-col>
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h3>Logboek</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <span
                            v-for="(log, l) in item.changelog.slice().reverse()"
                            :key="l"
                            ><i>{{ log }}</i
                            ><br
                          /></span>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-if="editProductDiv" flat>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-text-field label="Naam" v-model="item.title"
                      >Naam: <b>{{ item.title }}</b></v-text-field
                    >
                  </v-col>
                  <v-col class="d-flex justify-end"> </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-text-field
                      v-model="item.price"
                      outlined
                      label="Verkoops prijs "
                      type="number"
                      prefix="€"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-textarea
                      v-model="item.description"
                      outlined
                      label="Beschrijving"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-textarea
                      v-model="item.suppliers"
                      outlined
                      label="Leveranciers (bestelhistorie, contactgegevens, prijzen, etc)"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-btn text @click="editProductInfo(item.id)"
                      ><span class="ahcRed">Opslaan</span></v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn text @click="deleteItemID = item.id, deleteDialog = true"
                      ><span class="ahcRed">Verwijderen</span></v-btn
                    >
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
              </v-card>
            </v-alert>
          </td>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span>€{{ item.price }}</span>
        </template>
        <template v-slot:[`item.stockIntern`]="{ item }">
          <span>{{ getInternalStock(item) }}</span>
        </template>
        <template v-slot:[`item.stockExtern`]="{ item }">
          <span>{{ getExternalStock(item) }}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="tuneStockDialog"
    >
      <v-card v-if="!buzzy">
        <v-system-bar class="pa-4" color="#ebb63b">
          <span class="ahcRed subheading"
            >Voorraad van {{ editStock.itemTitle }} op locatie
            {{ editStock.fromTitle }} aanpassen
          </span>
        </v-system-bar>
        <span class="ma-5"></span>
        <v-select
          :items="['Verplaatst', 'Verkocht', 'Gekocht', 'Kapot', 'Gebruikt']"
          label="Actie"
          class="ma-3"
          v-model="editStock.action"
        ></v-select>
        <v-slider
          v-model="editStock.amount"
          class="ma-3"
          thumb-label="always"
          color="#ebb63b"
          label="Hoeveel?"
          min="1"
          :max="editStock.action === 'Gekocht' ? 100 : getMaxAmount(editStock.fromTitle, editStock.itemID)"
        ></v-slider>
        
        <v-select
          v-if="editStock.action === 'Verplaatst'"
          :items="stockTitles"
          label="Naar"
          class="ma-3"
          v-model="editStock.toTitle"
        ></v-select>
        <v-text-field
          v-if="editStock.action === 'Verkocht'"
          number
          label="Voor welk bedrag (per stuk)?"
          v-model="editStock.price"
        ></v-text-field>
        <v-text-field
          v-if="
            editStock.action === ('Verkocht' || 'Kapot' || 'Gebruikt' )
          "
          label="Beschrijving"
          v-model="editStock.note"
        ></v-text-field>
        <!-- <span class="ma-5"
          >TODO: 'Verkoop', 'relatiegeschenk' en 'kapot' wordt nog niet
          verwerkt</span
        > -->
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="(tuneStockDialog = false), (editStock = {})"
            ><span class="ahcRed">Annuleren</span></v-btn
          >

          <v-btn
            v-if="
              (editStock.action == 'Verplaatst' && editStock.toTitle) ||
              (editStock.action == 'Verkocht' && editStock.price) ||
              editStock.action ==
                ( editStock.action == 'Kapot' || editStock.action == 'Gebruikt') || editStock.action == 'Gekocht'
            "
            text
            @click="tuneStock"
            ><span class="ahcRed">Aanpassen</span></v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Aanpassen voorraad...</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" v-if="!buzzy" max-width="450">
      <v-card>
        <v-card-title class="headline" style="word-break: break-word; overflow-wrap: break-word;">Weet je zeker dat je dit product wilt verwijderen?</v-card-title>
        <v-card-actions >
          <v-btn @click="deleteDialog = false, deleteItemID = null">Annuleren</v-btn>
          <v-btn @click="deleteItem()" color="red" dark>Verwijderen</v-btn>
        </v-card-actions>
        
      </v-card>	
    </v-dialog>
  </v-container>
</template>

<script>
import router from "../../router/index";
export default {
  data() {
    return {
      view: "overview",
      search: "",
      productHeaders: [
        { text: "Naam", value: "title", align: "start" },
        { text: "Prijs", value: "price" },
        { text: "Voorraad intern", value: "stockIntern" },
        { text: "Voorraad extern", value: "stockExtern" },
        { text: "", value: "data-table-expand" },
      ],
      editProductDiv: false,
      expanded: [],
      singleExpand: true,
      tuneStockDialog: false,
      editStock: {},
      buzzy: false,
      deleteItemID: null,
      deleteDialog: false,
    };
  },
  computed: {
    products() {
      let products = [];
      if (this.$store.state.webshop) {
        products = this.$store.state.webshop.products;
      }
      return products;
    },
    stock() {
      let stock = [];
      if (this.$store.state.webshop !== null) {
        stock = this.$store.state.webshop.stock;
      }
      return stock;
    },
    stockTitles() {
      let stockTitles = [];
      if (this.$store.state.webshop !== null) {
        this.$store.state.webshop.stock.forEach((x) => {
          stockTitles.push(x.title);
        });
      }
      return stockTitles;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
    images() {
      return this.$store.state.productImages;
    },
  },
  methods: {
    toNewProduct() {
      router.push({ name: "AddNewProduct" });
    },
    
    getStockAmount(loc, prod) {
      let amount = 0;
      this.stock.forEach((location) => {
        if (location.title == loc) {
          location.products.forEach((item) => {
            if (item.id == prod) {
              amount = item.amount;
            }
          });
        }
      });
      return amount;
    },
    getInternalStock(product) {
      let amount = 0;
      this.stock.forEach((x) => {
        if (x.internal) {
          x.products.forEach((y) => {
            if (y.id === product.id) {
              amount += y.amount;
            }
          });
        }
      });
      return amount;
    },
    getExternalStock(product) {
      let amount = 0;
      this.stock.forEach((x) => {
        if (!x.internal) {
          x.products.forEach((y) => {
            if (y.id === product.id) {
              amount += y.amount;
            }
          });
        }
      });
      return amount;
    },
    getMaxAmount(stockTitle, itemID) {
      let amount = 0;
      this.stock.forEach((x) => {
        if (x.title == stockTitle) {
          x.products.forEach((y) => {
            if (y.id == itemID) {
              amount = y.amount;
            }
          });
        }
      });
      return amount;
    },
    tuneStock() {
      this.buzzy = true;
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      if (this.editStock.action == "Verplaatst") {
        this.stock.forEach((x) => {
          if (x.title == this.editStock.fromTitle) {
            x.products.forEach((y) => {
              if (y.id == this.editStock.itemID) {
                y.amount -= this.editStock.amount;
              }
            });
          } else if (x.title == this.editStock.toTitle) {
            x.products.forEach((y) => {
              if (y.id == this.editStock.itemID) {
                y.amount += this.editStock.amount;
              }
            });
          }
        });
        let msg = `${this.userName} heeft ${this.editStock.amount} stuks verplaatst van ${this.editStock.fromTitle} naar ${this.editStock.toTitle} op ${creationDate}`;
        this.products.forEach((x) => {
          if (x.id === this.editStock.itemID) {
            x.changelog.push(msg);
          }
        });
      }
      if (this.editStock.action == "Gekocht"){
        this.stock.forEach(x =>{
          if(x.title == this.editStock.fromTitle){
            x.products.forEach(y => {
              if(y.id == this.editStock.itemID){
                y.amount += this.editStock.amount;
              }
            })
          }
        })
        let msg = `${this.userName} heeft ${this.editStock.amount} stuks gekocht op ${creationDate}`;
        this.products.forEach((x) => {
          if (x.id === this.editStock.itemID) {
            x.changelog.push(msg);
          }
        });
      }
      this.$store.dispatch("saveWebshop");
      this.buzzy = false;
      this.editStock = {};
      this.tuneStockDialog = false;
    },
    editProductInfo(id) {
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let msg = `${this.userName} heeft het product aangepast op ${creationDate}`;
      this.products.forEach((x) => {
        if (x.id == id) {
          x.price = parseFloat(parseFloat(x.price).toFixed(2));
          x.deliveryPrice = parseFloat(parseFloat(x.deliveryPrice).toFixed(2));
          x.changelog.push(msg);
        }
      });
      this.$store.dispatch("saveWebshop");
      this.editProductDiv = false;
    },
    deleteItem() {
      this.buzzy = true;
      for(let i = 0; i < this.products.length; i++){
        if(this.products[i].id == this.deleteItemID){
          this.products.splice(i, 1);
        }
      }
      for(let i = 0; i < this.stock.length; i++){
        for(let j = 0; j < this.stock[i].products.length; j++){
          if(this.stock[i].products[j].id == this.deleteItemID){
            this.stock[i].products.splice(j, 1);
          }
        }
      }
      this.$store.dispatch("saveWebshop");
      this.buzzy = false;
      this.deleteDialog = false;
      this.deleteItemID = null;
    },
  },
  beforeCreate() {
    if (this.$store.state.webshop === null) {
      this.$store.dispatch("loadWebshop");
    }
  },
};
</script>

<style>
</style>