<template>
  <v-container>
      <h1 class="ma-4 d-flex" v-if="!loadingScreen">
        <span class="ahcRed"
          >Commissie {{ commissionContent.index }} - {{ commissionContent.name }}</span
        >
      </h1>
    <v-tabs v-if="!loadingScreen" class="my-5" fixed-tabs background-color="#ebb63b" dark>
      <v-tab @click="view = 'Tasks'"> Taken </v-tab>
      <v-tab @click="view = 'Draaiboek'"> Draaiboek </v-tab>
      <v-tab @click="view= 'Documenten'">Documenten</v-tab>
      <v-tab @click="view = 'Financial'">Financieel</v-tab>
      <v-tab @click="view = 'Leden'">Leden</v-tab>
    </v-tabs>
    <Tasks v-if="view == 'Tasks' && !loadingScreen" :draaiboeken="draaiboeken" :isPreces="isPreces" :commissionId="commissionContent.id" :tasks="tasks"/>
    <InteractiveDraaiboek v-if="view == 'Draaiboek' && !loadingScreen" :draaiboekCategories="draaiboeken" :isPreces="isPreces" :commissionId="commissionContent.id"/>
    <ComMembers v-if="view == 'Leden' && !loadingScreen" :preces="preces" :commissionMembers="commissionMembers" />
    <WorkgroupFinancials v-if="view == 'Financial' && !loadingScreen" :commissionIndex="parseInt(commissionContent.index)"/>
    <v-layout row wrap class="ma-3" name="layoutInfoModules">
      <v-flex xs12 md6 name="flexMembersPurpose">
            <v-flex v-if="view == 'Documenten'" class="ma-2" xs12 name="flexScript">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Draaiboek en begroting</b>
              </span>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="isPreces && !editWorkgroupScript">
                <v-icon color="#cc212f" @click="editWorkgroupScript = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text
              v-if="!editWorkgroupScript && commissionContent.scripts && commissionContent.scripts.length == 0"
            >
              <span>
                Er is voor deze commissie nog geen draaiboek geupload. Het is de
                taak van de voorzitter om dit te verzorgen.
              </span>
            </v-card-text>

            <v-form class="pa-2" v-if="editWorkgroupScript">
              <h4>Nieuw draaiboek of begroting uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newScript.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newScript.description"
              >
              </v-textarea>
              <v-radio-group v-model="editWorkgroupScriptRadiogroup">
                <v-radio :value="0" label="Document (word, excel, ppx, etc..)"></v-radio>
                <v-radio :value="1"
                  label="Link (Google doc, Google spreadsheet, etc..)"
                ></v-radio>
              </v-radio-group>
              <v-file-input
                v-if="editWorkgroupScriptRadiogroup == 0"
                label="te uploaden document: "
                v-model="newScript.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-text-field
                v-if="editWorkgroupScriptRadiogroup == 1"
                label="link: "
                v-model="newScript.link"
              ></v-text-field>
              <v-btn @click="(editWorkgroupScript = false), (newScript = {})"
                >Annuleren</v-btn
              >
              <v-btn
                v-if="newScript.title && (newScript.doc || newScript.link)"
                @click="saveWorkgroupScript()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list
              v-if="commissionContent.scripts && commissionContent.scripts.length > 0 && !editWorkgroupScript"
              three-line
            >
              <v-list-item
                v-for="(script, i) in commissionContent.scripts"
                :key="i"
              >
                <v-list-item-icon v-if="script.link">
                  <a :href="'//' + script.link" target="_blank">
                    <v-icon>mdi-open-in-new </v-icon>
                  </a>
                </v-list-item-icon>
                <v-list-item-icon
                  v-if="script.doc_id"
                  @click="
                    downloadFile(script.title, script.doc_id, script.type, script.commissionId)
                  "
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ script.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="script.description">{{
                    script.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ script.creationDate }} - {{ script.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  v-if="isPreces"
                  @click="
                    (deleteScriptDialog = true), (deleteScript = {id: script.id, commissionId: script.commissionId})
                  "
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
        
      </v-flex>
      <v-flex xs12 md6 name="flexMembersdocs">
        <v-flex v-if="view == 'Documenten'" class="ma-2" xs12 name="flexDocs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Documenten</b>
              </span>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="!editWorkgroupDoc">
                <v-icon color="#cc212f" @click="editWorkgroupDoc = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text
              v-if="!editWorkgroupDoc && commissionContent.docs && commissionContent.docs.length == 0"
            >
              <span>
                Er zijn voor deze commissie nog geen documenten geupload. Ieder
                commissielid kan hier documenten of google drive links opslaan.
              </span>
            </v-card-text>

            <v-form class="pa-2" v-if="editWorkgroupDoc">
              <h4>Nieuw document uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newDoc.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newDoc.description"
              >
              </v-textarea>
              <v-radio-group v-model="editWorkgroupDocRadiogroup">
                <v-radio :value="0" label="Document (word, excel, ppx, etc..)"></v-radio>
                <v-radio :value="1"
                  label="Link (Google doc, Google spreadsheet, etc..)"
                ></v-radio>
              </v-radio-group>
              <v-file-input
                v-if="editWorkgroupDocRadiogroup == 0"
                label="te uploaden document: "
                v-model="newDoc.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-text-field
                v-if="editWorkgroupDocRadiogroup == 1"
                label="link: "
                v-model="newDoc.link"
              ></v-text-field>
              <v-btn @click="(editWorkgroupDoc = false), (newDoc = {})"
                >Annuleren</v-btn
              >
              <v-btn
                v-if="newDoc.title && (newDoc.doc || newDoc.link)"
                @click="saveWorkgroupDoc()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list
              v-if="commissionContent.docs && commissionContent.docs.length > 0 && !editWorkgroupDoc"
              three-line
            >
              <v-list-item v-for="(doc, i) in commissionContent.docs" :key="i">
                <v-list-item-icon v-if="doc.link">
                  <a :href="'//' + doc.link" target="_blank">
                    <v-icon>mdi-open-in-new </v-icon>
                  </a>
                </v-list-item-icon>
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type, doc.commissionId)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ doc.creationDate }} - {{ doc.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  @click="(deleteDocDialog = true), (deleteDoc = {id: doc.id, commissionId: doc.commissionId})"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
      </v-flex> 
    </v-layout>

    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteScriptDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass"
          >Draaiboek/begroting verwijderen?</v-card-title
        >
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteScriptDialog = false), (deleteScript = null)"
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            @click="deleteScriptF(deleteScript.id, deleteScript.commissionId)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteDocDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Document verwijderen?</v-card-title>
        <v-card-text
          >Deze actie is onomkeerbaar, doe dit altijd in overleg met je
          commissieleden</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDocDialog = false), (deleteDoc = null)"
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="deleteDocF(deleteDoc.id, deleteDoc.commissionId)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-card class="ma-5" elevation="0" v-if="loadingScreen">
          <v-row class="d-flex justify-space-around mb-6">
            <v-progress-circular
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-space-around mb-6">
            <h3>Inladen commissie</h3>
          </v-row>
        </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase";
import Axios from "axios";
import WorkgroupFinancials from "./workgroupModules/workgroupFinancials.vue";
import InteractiveDraaiboek from "./workgroupModules/interactiveDraaiboek.vue";
import Tasks from "./workgroupModules/Tasks.vue";
import ComMembers from "./workgroupModules/ComMembers.vue";
export default {
  components: {
    WorkgroupFinancials,
    InteractiveDraaiboek,
    Tasks,
    ComMembers,
  },
  data() {
    return {
      loadingScreen: true,
      view: "Tasks",
      editGoal: false,
      editActiveContent: false,
      editWorkgroupScript: false,
      editWorkgroupScriptRadiogroup: 0,
      newScript: {},
      deleteScript: null,
      deleteScriptDialog: false,
      editWorkgroupDoc: false,
      editWorkgroupDocRadiogroup: 0,
      newDoc: {},
      deleteDoc: null,
      deleteDocDialog: false,
      buzzy: false,
      changeGoal: false,
      editCalendar: false,
      changeCalendar: false,
      newCalendarDate: {},
      //datePicker: false,
      calendarHeaders: [
        {
          text: "Datum",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Activiteit",
          sortable: false,
          value: "name",
        },
        {
          text: "Opmerkingen",
          sortable: false,
          value: "comment",
        },
        { sortable: false, value: "actions" },
      ],
    };
  },
  computed: {
    
    commissionContent() {
      return this.$store.state.commissionStore.commissionContent;
    },
    isPreces() {
      let isPreces = false
      this.preces.forEach(preces => {
        if (preces.id === this.$store.state.currentUser.id) {
          isPreces = true;
        }
      });
      return isPreces;
    },

    tasks() {
      return this.commissionContent.taskCategories ?? [];    },
    
    userName() {
      return this.$store.state.currentUser.name;
    },
    accountancy() {
      return this.$store.state.accountancy;
    },
    commissionMembers() {
      return this.$store.getters["commissionStore/getCommissionUsers"](this.commissionContent.id);
    },
    preces() {
      return this.$store.getters["commissionStore/getCommissionPreces"](this.commissionContent.id);
    },
    draaiboeken() {
      return this.commissionContent.draaiboekCategories ?? [];
    },
  },
  watch: {
    commissionContent: {
      handler() {
        if(this.commissionContent?.index) {
          this.loadingScreen = false;
        }
        this.loadingScreen = false;
      },
      deep: true,
    },
  },
  methods: {
 



    saveWorkgroupScript() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        if (this.newScript.link) {
          this.newScript.link = this.newScript.link.replace("https://", "");
          this.newScript.id = id;
          this.newScript.commissionId = this.$route.params.id;
          this.newScript.creator = creator;
          this.newScript.creationDate = creationDate;
          this.$store.dispatch("commissionStore/saveCommissionScript", {
            commissionId: this.$route.params.id,
            script: this.newScript,
          });
          this.newScript = {};
          this.editWorkgroupScript = false;
        } else if (this.newScript.doc) {
          let script = {
            id: id,
            title: this.newScript.title,
            description: this.newScript.description,
            doc_id: id,
            commissionId: this.$route.params.id,
            creator: creator,
            creationDate: creationDate,
            type: this.newScript.doc.name.split(".").pop(),
          };

          let storageRef = firebase.storage().ref();
          storageRef
            .child(`docs/C${script.commissionId}/${id}`)
            .put(this.newScript.doc);
            this.$store.dispatch("commissionStore/saveCommissionScript", {
            commissionId: this.$route.params.id,
            script: script,
          });
          this.newScript = {};
          this.editWorkgroupScript = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    saveWorkgroupDoc() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        if (this.newDoc.link) {
          this.newDoc.link = this.newDoc.link.replace("https://", "");
          this.newDoc.id = id;
          this.newDoc.commissionId = this.$route.params.id;
          this.newDoc.creator = creator;
          this.newDoc.creationDate = creationDate;
          this.$store.dispatch("commissionStore/saveCommissionDoc", {
            commissionId: this.$route.params.id,
            doc: this.newDoc,
          });
          this.newDoc = {};
          this.editWorkgroupDoc = false;
        } else if (this.newDoc.doc) {
          let doc = {
            id: id,
            commissionId: this.$route.params.id,
            title: this.newDoc.title,
            description: this.newDoc.description,
            doc_id: id,
            creator: creator,
            creationDate: creationDate,
            type: this.newDoc.doc.name.split(".").pop(),
          };

          let storageRef = firebase.storage().ref();
          storageRef
            .child(`docs/C${doc.commissionId}/${id}`)
            .put(this.newDoc.doc);
            this.$store.dispatch("commissionStore/saveCommissionDoc", {
            commissionId: this.$route.params.id,
            doc: doc,
          });
          this.newDoc = {};
          this.editWorkgroupDoc = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    downloadFile(title, id, file_type, commissionId) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;
      let file = firebase
        .storage()
        .ref(`docs/C${commissionId}/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
    deleteScriptF(id, commissionId) {
      this.buzzy = true;
      for (let i = 0; i < this.commissionContent.scripts.length; i++) {
        if (this.commissionContent.scripts[i].id === id) {
          if (this.commissionContent.scripts[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef
              .child(`docs/C${commissionId}/${id}`)
              .delete();
          }
          this.$store.dispatch("commissionStore/deleteCommissionScript", {
            commissionId: this.$route.params.id,
            scriptId: id,
          });
        }
      }
      this.deleteScript = null;
      setTimeout(() => {
        this.deleteScriptDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    deleteDocF(id, commissionId) {
      this.buzzy = true;
      for (let i = 0; i < this.commissionContent.docs.length; i++) {
        if (this.commissionContent.docs[i].id === id) {
          if (this.commissionContent.docs[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef
              .child(`docs/C${commissionId}/${id}`)
              .delete();
          }
          this.$store.dispatch("commissionStore/deleteCommissionDoc", {
            commissionId: this.$route.params.id,
            docId: id,
          });
        }
      }
      this.deleteDoc = null;
      setTimeout(() => {
        this.deleteDocDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    
    downloadReceipt(id, file_type) {
      let metadata;
      if (file_type === "doc" || file_type === "dot") {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (
        file_type === "xls" ||
        file_type === "xlt" ||
        file_type === "xla"
      ) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (
        file_type === "ppt" ||
        file_type === "pot" ||
        file_type === "pps" ||
        file_type === "ppa"
      ) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      } else if (file_type === "png") {
        metadata = "image/png";
      } else if (
        file_type === "jpg" ||
        file_type === "jpeg" ||
        file_type === "JPG" ||
        file_type === "JPEG"
      ) {
        metadata = "image/jpeg";
      }

      let filename = `Factuur_C${this.$route.params.id}_${id}`;
      let file = firebase
        .storage()
        .ref(`accountancy/C${this.$route.params.id}/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },

  beforeCreate() {
    this.$store.dispatch("commissionStore/loadCommissionContent", {id: this.$route.params.id});
    this.$store.dispatch("loadAccountancy");
  },
  beforeRouteLeave(to, from, next) {
    if (this.changeGoal) {
      const answer = window.confirm(
        "Weet je zeker dat je de pagina wilt verlaten zonder de wijzigingen op te slaan?"
      );
      if (answer) {
        this.change = false;
        next();
      } else {
        next(false);
      }
    } else if (this.changeCalendar) {
      const answer = window.confirm(
        "Weet je zeker dat je de pagina wilt verlaten zonder de wijzigingen in de kalender op te slaan?"
      );
      if (answer) {
        this.change = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.$store.dispatch("commissionStore/clearCommissionContent");
      next();
    }
  },
};
</script>

<style>
</style>