<template>
  <v-app>
    <Navbar v-if="loggedIn" />
    <v-main class="ma-4">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <Bottom v-if="loggedIn" />
  </v-app>
</template>

<script>
import Navbar from "./views/Navbar";
import Bottom from "./views/Bottom";

export default {
  name: "App",
  components: {
    Navbar,
    Bottom,
  },
  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
  },
};
</script>
