<template>
  <v-container>
    <h1 class="ahcRed ma-4">Administratie krant</h1>
    <v-tabs v-if="!loadingScreen" class="my-5" fixed-tabs background-color="#ebb63b" dark>
      <v-tab @click="view = 'Edities'"> Overzicht </v-tab>
      <v-tab @click="view = 'Adverteerders'"> Adverteerders </v-tab>
      <v-tab @click="view= 'Redactie'">Redactie</v-tab>
      <v-tab @click="view = 'Financial'">Financieel</v-tab>
    </v-tabs>
    <EditiesOverview
      v-if="view == 'Edities' && !loadingScreen"
      :editions="editions"
      :advertisers="advertisers"/>
    <AdverteerderOverview
      v-if="view == 'Adverteerders' && !loadingScreen"
      :personalOverView="false"
      :advertisers="advertisers"
      :activeEdition="activeEdition"/>
      <RedactieOverview v-if="view == 'Redactie' && !loadingScreen" :advertisers="advertisers"/>
      <FinancialOverview v-if="view == 'Financial' && !loadingScreen"/>
    

      <v-card class="ma-5" elevation="0" v-if="loadingScreen">
          <v-row class="d-flex justify-space-around mb-6">
            <v-progress-circular
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-space-around mb-6">
            <h3>Inladen administratie</h3>
          </v-row>
        </v-card>
  </v-container>
</template>

<script>
import AdverteerderOverview from "./AdverteerderOverview.vue";
import EditiesOverview from "./EditionsOverview.vue";
import RedactieOverview from "./RedactieOverview.vue";
import FinancialOverview from "./FinancialOverview.vue";

export default {
  components: {
    AdverteerderOverview,
    EditiesOverview,
    RedactieOverview,
    FinancialOverview,
  },
  data() {
    return {
      view: "Edities",
      loadingScreen: true,
    };
  },
  computed: {
    advertisers() {
      return this.$store.state.paperStore.advertisers;
    },
    editions() {
      return this.$store.state.paperStore.paperEditions;
    },
    activeEdition(){
      let activeEditionId = this.$store.state.paperStore.activeEditionId;
      let editions = this.$store.state.paperStore.paperEditions;
      return editions.find(edition => edition.id == activeEditionId);
    },
  },
  methods: {
  
  },
  created() {
    this.$store.dispatch("loadUsers");
    setTimeout(() => {
      this.loadingScreen = false;
    }, 2500);
  },
  beforeCreate() {
    this.$store.commit("paperStore/loadAdvertisers");
    this.$store.commit("paperStore/loadPaperEditions");
  },
};
</script>

<style>
</style>