<template>
   <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Optocht</span>
      </h1>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutEditModules">
      <v-flex name="flexIntro">
        <v-flex class="ma-2" xs12 name="flexIntro">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Introductie tekst optocht</b>
              </span>
            </v-system-bar>
            <v-textarea
              class="pa-2"
              v-model="summerIntro"
              disabled
              v-if="lockIntro"
            ></v-textarea>
            <v-textarea
              class="pa-2"
              v-model="newIntro"
              v-if="!lockIntro"
            ></v-textarea>
            <v-card-actions>
              <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
              <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
              <v-btn v-if="!lockIntro" @click="lockIntro = true"
                >Annuleren</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-flex>
        <v-flex class="ma-2" xs12 name="flexDocs" v-if="contentSummer.docs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Downloads</b>
              </span>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="!editDocs">
                <v-icon color="#cc212f" @click="editDocs = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text v-if="!editDocs && contentSummer.docs.length == 0">
              <span> Er zijn nog geen documenten geupload. </span>
            </v-card-text>

            <v-form class="pa-2" v-if="editDocs">
              <h4>Nieuw documenten uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newDoc.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newDoc.description"
              >
              </v-textarea>
              <v-file-input
                label="te uploaden document: "
                v-model="newDoc.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-btn @click="(editDocs = false), (newDoc = {})"
                >Annuleren</v-btn
              >
              <v-btn v-if="newDoc.title && newDoc.doc" @click="saveNewDoc()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list v-if="contentSummer.docs.length > 0 && !editDocs" three-line>
              <v-list-item v-for="(doc, i) in contentSummer.docs" :key="i">
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ doc.creationDate }} - {{ doc.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  @click="(deleteDocDialog = true), (deleteDoc = doc.id)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
    </v-layout>
        <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteDocDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Document verwijderen?</v-card-title>
        <v-card-text>Deze actie is onomkeerbaar.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDocDialog = false), (deleteDoc = null)"
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="deleteDocF(deleteDoc)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Axios from "axios";
import firebase from "firebase";
export default {
    data(){
        return{
              lockIntro: true,
      newIntro: null,
         newDoc: {},
      deleteDoc: null,
      editDocs: false,
      deleteDocDialog: false,
      buzzy: false,
        }
    },
    computed: {
           summerIntro() {
      return this.$store.state.intros.summer;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
      contentSummer() {
      return this.$store.state.contentSummer;
    },
    },
    methods: {
            changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.summerIntro;
    },
    updateIntro() {
      let intro = {
        name: "summer",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
    saveNewDoc() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        let doc = {
          id: id,
          title: this.newDoc.title,
          description: this.newDoc.description,
          doc_id: id,
          creator: creator,
          creationDate: creationDate,
          type: this.newDoc.doc.name.split(".").pop(),
        };

        let storageRef = firebase.storage().ref();
        storageRef.child(`publicDocs/summer/${id}`).put(this.newDoc.doc);
        this.contentSummer.docs.push(doc);
        this.$store.dispatch("updateContentSummer", {
          content: this.contentSummer,
        });
        this.newDoc = {};
        this.editDocs = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    deleteDocF(id) {
      this.buzzy = true;
      for (let i = 0; i < this.contentSummer.docs.length; i++) {
        if (this.contentSummer.docs[i].id === id) {
          if (this.contentSummer.docs[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef.child(`publicDocs/summer/${id}`).delete();
          }
          this.contentSummer.docs.splice(i, 1);
          this.$store.dispatch("updateContentSummer", {
            content: this.contentSummer,
          });
        }
      }
      this.deleteDoc = null;
      setTimeout(() => {
        this.deleteDocDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;
      let file = firebase.storage().ref(`publicDocs/summer/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },
  created() {
    this.$store.dispatch("loadContentSummer");
  },
}
</script>

<style>

</style>