<template>
  <div>
    <h1 class="ma-4 d-flex">
      <span class="ahcRed">Gebruiker toevoegen</span>
    </h1>
    <v-container class="grey--text" v-if="!buzzy">
      <v-col cols="12" sm="6">
        <v-form ref="form" align="center">
          <v-text-field
            v-model="name"
            label="Naam"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.validEmail]"
            label="E-mail"
            required
          ></v-text-field>
          <v-text-field
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            label="Wachtwoord"
            @click:append="show = !show"
            v-model="password"
            required
          ></v-text-field>
          <v-checkbox
          v-model="isExternal"
          label="Extern account (geen lid van de stichting)" 
        ></v-checkbox>
          <div class="text-center mb-2">
            <span class="center red--text" v-if="feedback">{{ feedback }}</span>
          </div>
          <Permissions  :userPermissions="permissions" @update-permissions="onUpdatePermissions"></Permissions>
          <v-row justify="center">
            <v-btn @click="addUser" outlined rounded text class="ma-2">
              <span>Toevoegen</span>
              <v-icon small right>mdi-account-plus-outline</v-icon>
            </v-btn>
            <v-btn outlined rounded text class="ma-2">
              <router-link :to="{ name: 'Users' }" class="black--text">
                <span>Terug</span>
                <v-icon small right>mdi-arrow-left-circle</v-icon>
              </router-link>
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-container>
    <v-progress-circular v-if="buzzy"
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
  </div>
</template>

<script>
import Permissions from './Permissions.vue';

export default {
  name: "add-user",
  components: {
    Permissions,
  },
  data() {
    return {
      name: null,
      email: null,
      isExternal: false,
      buzzy: false,
      permissions: {
        general: [],
        commissions: [],
      },
      password: "",
      show: false,
      feedback: null,
      rules: {
        required: (value) => !!value || "Verplicht",
        validEmail: (v) =>
          /.+@.+\..+/.test(v) || "E-mail voldoet niet aan het juiste formaat",
        min: (v) => v.length >= 8 || "Min 8 karakters",
      },
    };
  },
  methods: {
    addUser() {
      this.buzzy = true;
      if (this.name && this.email && this.password) {
        let user = {
          password: this.password,
          name: this.name,
          email: this.email,
          isExternal: this.isExternal,
          permissions: this.permissions,
        };
        this.$store.dispatch("addUser", user).catch((error) => {
          this.feedback = error.message;
        });
      } else {
        this.buzzy = false;
        this.feedback = "Alle velden moeten gevuld worden";
      }
    },
    onUpdatePermissions(permissions) {
      this.permissions = permissions;
    },
  },
};
</script>

<style>
</style>